h1, h2, h3, h4 {
  color: var(--tertiary-color);
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

h1 {
  font-size: 3rem;
  color: var(--secondary-color);
  padding: 0;
  position: relative;
  display: inline-block;
  margin: 0;
}

p {
  font-size: 1rem;
  color: var(--text-color);
  word-break: break-word;
}

@media only screen and (max-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 2.25rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }

}

@media only screen and (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.25rem;
  }
  h3 {
    font-size: 1rem;
  }
  h4 {
    font-size: 1rem;
  }
  p {
    font-size: 14px;
  }
}
