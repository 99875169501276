* {

  scrollbar-width: thin;
  scrollbar-color: var(--secondary-color) var(--primary-color);
}
/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 6px;
}
*::-webkit-scrollbar-track {
  background: var(--primary-color);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 0;
}

.btn {
  font-size: 1rem !important;
  background: var(--primary-color) !important;
  color: var(--text-color) !important;
  border: 2px solid var(--secondary-color) !important;
  border-radius: 0 !important;
  padding: .5rem !important;
  &:focus {
    background: var(--primary-color);
    border-color: var(--tertiary-color);
    color: var(--tertiary-color);
    outline: none;
    box-shadow: none;
  }
}

a {
  font-family: 'Montserrat', sans-serif;
  color: var(--secondary-color);
  font-weight: 300;
  &:hover {
    text-decoration: none;
    color: var(--text-color)
  }
}

.big {
  height: 400px;
}

.alignright {
  text-align: right;
}

.alignleft {
  text-align: left;
}

.aligncenter {
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .btn {
    font-size: 12px !important;
    padding: .25rem !important;
  }
}
