
header {
  min-height: var(--header-height);
  z-index: 999;
}

.logo {
  transform: scale(0);
  opacity: 0;
  padding: 0;
  animation-name: appearance;
  animation-duration: .6s;
  animation-delay: .6s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.75,-0.45,.3,1.4);
  transform-origin: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
