.animation {

  opacity: 0;
  animation-duration: var(--transition-speed);
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
  &--zoom--out {
    animation-name: zoom--out;
  }
  &--top {
    animation-name: appearance--top;
  }
  &--bottom {
    animation-name: appearance--bottom;
  }
  &--left {
    animation-name: appearance--left;
  }
  &.el_in_viewport {
    animation-play-state: running;
  }


}

@keyframes appearance {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes wordAppearance {
  from {
    transform: translateY(200%) translate3d(1px, 1px, 1px);
    opacity: 0;
  }
  to {
    transform: translateY(0) translate3d(1px, 1px, 1px);
    opacity: 1;
  }
}

@keyframes appearance--top {
  0% {
    transform: translateZ(0) translateY(-25px);
    opacity: 0;
  }

  100% {
    transform: translateZ(0) translateY(0);

    opacity: 1;
  }
}

@keyframes appearance--top--out {
  0% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateZ(0) translateY(-25px);

    opacity: 0;
  }
}

@keyframes appearance--bottom {
  0% {
    transform: translateZ(0) translateY(25px);
    opacity: 0;
  }

  100% {
    transform: translateZ(0) translateY(0);

    opacity: 1;
  }
}

@keyframes appearance--bottom--out {
  0% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateZ(0) translateY(25px);

    opacity: 0;
  }
}
@keyframes appearance--left {
  from {
    transform: translateX(-75px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes zoom--out {
  0% {
    transform: translateZ(0) scale(1.1);
    opacity: 0;
  }
  100% {
    transform: translateZ(0) scale(1);

    opacity: 1;
  }
}

@keyframes zoom--out--out {
  0% {
    transform: translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateZ(0) scale(1.1);

    opacity: 0;
  }
}


.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50% -50%);
  display: inline-block;
  width: 80px;
  height: 80px;
}
.loading:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid var(--secondary-color);
  border-color: #fff transparent var(--secondary-color) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
