// Breakpoints

$breakpoint--xs : 576px;
$breakpoint--small : 767px;
$breakpoint--medium: 991px;
$breakpoint--large: 1199px;
$breakpoint--xl: 1400px;

:root {

  --l: 50%;

  --header-height: 115px;


  --primary-color: #00CCCC;
  --secondary-color: #fff;
  --tertiary-color: #FFD320;
  --text-color: #fff;

  // Font Sizes

  --menu-size: 1.75rem;

  // Padding

  --small-padding: .5rem;
  --medium-padding: 1rem;
  --big-padding: 2rem;


  // Transition

  --transition-speed : .6s;
  --faster-transition-speed : .3s;

  --transition-timing-function : cubic-bezier(0,1.09,.86,1.13);

  --transition-speed: .45s;

  --font-family:  'Bebas Neue', 'Roboto';




}

@media only screen and (max-height: 678px) {
  :root {
    --l: 50%;


    --padding-big: .75rem;
    --padding-medium: .5rem;
    --padding-small: .25rem;


  }
}


@media only screen and (max-width: 767px) {


  :root {

    --l: 50%;


    --padding-big: .75rem;
    --padding-medium: .5rem;
    --padding-small: .25rem;


  }
}

:root {


}
