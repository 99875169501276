@font-face {
	font-family: 'Patua One';
	font-style: normal;
	font-weight: 400;
	src: url('./fonts/patua-one-v16-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/patua-one-v16-latin-regular.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/patua-one-v16-latin-regular.woff2')
			format('woff2'),
		/* Super Modern Browsers */ url('./fonts/patua-one-v16-latin-regular.woff')
			format('woff'),
		/* Modern Browsers */ url('./fonts/patua-one-v16-latin-regular.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/patua-one-v16-latin-regular.svg#PatuaOne') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 100;
	src: url('./fonts/montserrat-v25-latin_cyrillic-100.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/montserrat-v25-latin_cyrillic-100.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/montserrat-v25-latin_cyrillic-100.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('./fonts/montserrat-v25-latin_cyrillic-100.woff') format('woff'),
		/* Modern Browsers */ url('./fonts/montserrat-v25-latin_cyrillic-100.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/montserrat-v25-latin_cyrillic-100.svg#Montserrat')
			format('svg'); /* Legacy iOS */
}
/* montserrat-200 - latin_cyrillic */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 200;
	src: url('./fonts/montserrat-v25-latin_cyrillic-200.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/montserrat-v25-latin_cyrillic-200.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/montserrat-v25-latin_cyrillic-200.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('./fonts/montserrat-v25-latin_cyrillic-200.woff') format('woff'),
		/* Modern Browsers */ url('./fonts/montserrat-v25-latin_cyrillic-200.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/montserrat-v25-latin_cyrillic-200.svg#Montserrat')
			format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin_cyrillic */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src: url('./fonts/montserrat-v25-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/montserrat-v25-latin_cyrillic-regular.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/montserrat-v25-latin_cyrillic-regular.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('./fonts/montserrat-v25-latin_cyrillic-regular.woff') format('woff'),
		/* Modern Browsers */
			url('./fonts/montserrat-v25-latin_cyrillic-regular.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/montserrat-v25-latin_cyrillic-regular.svg#Montserrat')
			format('svg'); /* Legacy iOS */
}
/* montserrat-800 - latin_cyrillic */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 800;
	src: url('./fonts/montserrat-v25-latin_cyrillic-800.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/montserrat-v25-latin_cyrillic-800.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/montserrat-v25-latin_cyrillic-800.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('./fonts/montserrat-v25-latin_cyrillic-800.woff') format('woff'),
		/* Modern Browsers */ url('./fonts/montserrat-v25-latin_cyrillic-800.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/montserrat-v25-latin_cyrillic-800.svg#Montserrat')
			format('svg'); /* Legacy iOS */
}
/* montserrat-900 - latin_cyrillic */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 900;
	src: url('./fonts/montserrat-v25-latin_cyrillic-900.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/montserrat-v25-latin_cyrillic-900.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/montserrat-v25-latin_cyrillic-900.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('./fonts/montserrat-v25-latin_cyrillic-900.woff') format('woff'),
		/* Modern Browsers */ url('./fonts/montserrat-v25-latin_cyrillic-900.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/montserrat-v25-latin_cyrillic-900.svg#Montserrat')
			format('svg'); /* Legacy iOS */
}

/* roboto-100 - latin_cyrillic */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 100;
	src: url('./fonts/roboto-v30-latin_cyrillic-100.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/roboto-v30-latin_cyrillic-100.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/roboto-v30-latin_cyrillic-100.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('./fonts/roboto-v30-latin_cyrillic-100.woff') format('woff'),
		/* Modern Browsers */ url('./fonts/roboto-v30-latin_cyrillic-100.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/roboto-v30-latin_cyrillic-100.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin_cyrillic */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url('./fonts/roboto-v30-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/roboto-v30-latin_cyrillic-regular.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/roboto-v30-latin_cyrillic-regular.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('./fonts/roboto-v30-latin_cyrillic-regular.woff') format('woff'),
		/* Modern Browsers */ url('./fonts/roboto-v30-latin_cyrillic-regular.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/roboto-v30-latin_cyrillic-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin_cyrillic */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 900;
	src: url('./fonts/roboto-v30-latin_cyrillic-900.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/roboto-v30-latin_cyrillic-900.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/roboto-v30-latin_cyrillic-900.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('./fonts/roboto-v30-latin_cyrillic-900.woff') format('woff'),
		/* Modern Browsers */ url('./fonts/roboto-v30-latin_cyrillic-900.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/roboto-v30-latin_cyrillic-900.svg#Roboto') format('svg'); /* Legacy iOS */
}

@import './sass/variables';
@import './sass/transition';
@import './sass/error';
@import './sass/header';
@import './sass/navigation';
@import './sass/contact';
@import './sass/interface';
@import './sass/skills';
@import './sass/text';
@import './sass/footer';
@import './sass/animation';
body {
	background: var(--primary-color);
	color: var(--secondary-color);
	overflow-x: hidden;
	overflow-y: scroll;
}

.lazy {
	background-image: none;
}

.background {
	.parallax--element {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		width: 100%;
		height: 0;
		padding-bottom: 100%;
		box-sizing: content-box;
		border: 1.5rem var(--secondary-color) solid;
	}
}
.transition-group {
	position: relative;
}

.test {
	position: absolute;
	top: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	& > .container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: var(--big-padding);
		min-height: calc(100vh - 144px);
	}
}
.blog {
	padding: 2rem 0;
	p {
		padding: 0;
	}
	&:first-of-type {
		min-height: calc(100vh - 150px);
		h1 {
			font-size: clamp(3.44rem, 6.34vw + 0.75rem, 6.81rem);
      word-break: keep-all;
      position: relative;
      color: var(--tertiary-color);
      &::before {
        content: '<';
        color: var(--secondary-color);
      }
      &::after {
        content: ' />';
        position: absolute;
        right: -35%;
        color: var(--secondary-color);

      }
		}
	}
}

.modal-backdrop.show {
	opacity: 0.975;
}

.modal {
	padding: 0 !important;
	overflow: hidden !important;
	.modal-dialog {
		.modal-content {
			background-color: transparent;
			border-radius: 0;
			border: none;
			.modal-header {
				position: absolute;
				top: 0;
				right: 0.5rem;
				z-index: 999;
				border: none;
				.line {
					&:first-of-type {
						transform: rotate(-45deg);
					}
					&:last-of-type {
						transform: rotate(45deg);
					}
				}

				.close {
					opacity: 1;
					color: var(--secondary-color);
					text-shadow: none;
					font-size: 3rem;
					&:focus {
						outline: 0;
					}
				}
			}
			.modal-body {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				padding: 0;
			}
		}
	}
}

.word {
	font-family: 'Roboto', sans-serif;
	font-family: 'Patua One', cursive;
	font-size: 1.25rem;
	//margin:0 .25rem 0 0;
	display: inline-block;
}

.footerBottom {
	min-height: calc(100vh - var(--header-height));
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.link {
	color: var(--secondary-color);
	text-decoration: underline;
}
