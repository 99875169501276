@font-face {
  font-family: "Patua One";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/patua-one-v16-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/patua-one-v16-latin-regular.eot?#iefix") format("embedded-opentype"), url("./fonts/patua-one-v16-latin-regular.woff2") format("woff2"), url("./fonts/patua-one-v16-latin-regular.woff") format("woff"), url("./fonts/patua-one-v16-latin-regular.ttf") format("truetype"), url("./fonts/patua-one-v16-latin-regular.svg#PatuaOne") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  src: url("./fonts/montserrat-v25-latin_cyrillic-100.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/montserrat-v25-latin_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("./fonts/montserrat-v25-latin_cyrillic-100.woff2") format("woff2"), url("./fonts/montserrat-v25-latin_cyrillic-100.woff") format("woff"), url("./fonts/montserrat-v25-latin_cyrillic-100.ttf") format("truetype"), url("./fonts/montserrat-v25-latin_cyrillic-100.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-200 - latin_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  src: url("./fonts/montserrat-v25-latin_cyrillic-200.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/montserrat-v25-latin_cyrillic-200.eot?#iefix") format("embedded-opentype"), url("./fonts/montserrat-v25-latin_cyrillic-200.woff2") format("woff2"), url("./fonts/montserrat-v25-latin_cyrillic-200.woff") format("woff"), url("./fonts/montserrat-v25-latin_cyrillic-200.ttf") format("truetype"), url("./fonts/montserrat-v25-latin_cyrillic-200.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-regular - latin_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/montserrat-v25-latin_cyrillic-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/montserrat-v25-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("./fonts/montserrat-v25-latin_cyrillic-regular.woff2") format("woff2"), url("./fonts/montserrat-v25-latin_cyrillic-regular.woff") format("woff"), url("./fonts/montserrat-v25-latin_cyrillic-regular.ttf") format("truetype"), url("./fonts/montserrat-v25-latin_cyrillic-regular.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-800 - latin_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: url("./fonts/montserrat-v25-latin_cyrillic-800.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/montserrat-v25-latin_cyrillic-800.eot?#iefix") format("embedded-opentype"), url("./fonts/montserrat-v25-latin_cyrillic-800.woff2") format("woff2"), url("./fonts/montserrat-v25-latin_cyrillic-800.woff") format("woff"), url("./fonts/montserrat-v25-latin_cyrillic-800.ttf") format("truetype"), url("./fonts/montserrat-v25-latin_cyrillic-800.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-900 - latin_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  src: url("./fonts/montserrat-v25-latin_cyrillic-900.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/montserrat-v25-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("./fonts/montserrat-v25-latin_cyrillic-900.woff2") format("woff2"), url("./fonts/montserrat-v25-latin_cyrillic-900.woff") format("woff"), url("./fonts/montserrat-v25-latin_cyrillic-900.ttf") format("truetype"), url("./fonts/montserrat-v25-latin_cyrillic-900.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* roboto-100 - latin_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: url("./fonts/roboto-v30-latin_cyrillic-100.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/roboto-v30-latin_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("./fonts/roboto-v30-latin_cyrillic-100.woff2") format("woff2"), url("./fonts/roboto-v30-latin_cyrillic-100.woff") format("woff"), url("./fonts/roboto-v30-latin_cyrillic-100.ttf") format("truetype"), url("./fonts/roboto-v30-latin_cyrillic-100.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-regular - latin_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/roboto-v30-latin_cyrillic-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/roboto-v30-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("./fonts/roboto-v30-latin_cyrillic-regular.woff2") format("woff2"), url("./fonts/roboto-v30-latin_cyrillic-regular.woff") format("woff"), url("./fonts/roboto-v30-latin_cyrillic-regular.ttf") format("truetype"), url("./fonts/roboto-v30-latin_cyrillic-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-900 - latin_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("./fonts/roboto-v30-latin_cyrillic-900.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/roboto-v30-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("./fonts/roboto-v30-latin_cyrillic-900.woff2") format("woff2"), url("./fonts/roboto-v30-latin_cyrillic-900.woff") format("woff"), url("./fonts/roboto-v30-latin_cyrillic-900.ttf") format("truetype"), url("./fonts/roboto-v30-latin_cyrillic-900.svg#Roboto") format("svg"); /* Legacy iOS */
}
:root {
  --l: 50%;
  --header-height: 115px;
  --primary-color: #00CCCC;
  --secondary-color: #fff;
  --tertiary-color: #FFD320;
  --text-color: #fff;
  --menu-size: 1.75rem;
  --small-padding: .5rem;
  --medium-padding: 1rem;
  --big-padding: 2rem;
  --transition-speed: .6s;
  --faster-transition-speed: .3s;
  --transition-timing-function: cubic-bezier(0,1.09,.86,1.13);
  --transition-speed: .45s;
  --font-family: "Bebas Neue", "Roboto";
}

@media only screen and (max-height: 678px) {
  :root {
    --l: 50%;
    --padding-big: .75rem;
    --padding-medium: .5rem;
    --padding-small: .25rem;
  }
}
@media only screen and (max-width: 767px) {
  :root {
    --l: 50%;
    --padding-big: .75rem;
    --padding-medium: .5rem;
    --padding-small: .25rem;
  }
}
.parallax--element {
  position: relative;
}

.fade-enter, .fade-appear {
  opacity: 0;
  transform-origin: bottom;
}
.fade-enter .form-group, .fade-appear .form-group {
  transform: scale(0);
}
.fade-enter .text-container, .fade-appear .text-container, .fade-enter .skill-name, .fade-appear .skill-name {
  transform: translateX(-2000%);
  opacity: 0;
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.fade-enter .parallax--element, .fade-appear .parallax--element {
  opacity: 0;
  transform: scale(0);
}
.fade-enter .progress-bar, .fade-appear .progress-bar {
  max-width: 0;
}
.fade-enter .progress-bar:after, .fade-appear .progress-bar:after {
  opacity: 0;
}

.fade-appear-done, .fade-enter-done {
  opacity: 1;
  transform: translateX(0);
  transform-origin: center;
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s;
}
.fade-appear-done .progress-bar, .fade-enter-done .progress-bar {
  max-width: 100%;
}
.fade-appear-done .progress-bar:after, .fade-enter-done .progress-bar:after {
  opacity: 1;
}
.fade-appear-done .parallax--element, .fade-enter-done .parallax--element {
  opacity: 0;
  transform: scale(0);
}
.fade-appear-done .parallax--element.el_in_viewport, .fade-enter-done .parallax--element.el_in_viewport {
  opacity: 1;
  transform: scale(1) translate3d(1px, 1px, 1px);
}
.fade-appear-done .parallax--element.el_in_viewport.project--images, .fade-enter-done .parallax--element.el_in_viewport.project--images {
  transition: all 0.6s cubic-bezier(1, 0.93, 0.5, 1.33) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-appear-done .parallax--element.el_in_viewport .word, .fade-enter-done .parallax--element.el_in_viewport .word {
  opacity: 0;
  transform: translateY(200%) translate3d(1px, 1px, 1px);
  animation-name: wordAppearance;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.75, -0.45, 0.3, 1.4);
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(1), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(1) {
  animation-delay: 0.52s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(2), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(2) {
  animation-delay: 0.54s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(3), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(3) {
  animation-delay: 0.56s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(4), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(4) {
  animation-delay: 0.58s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(5), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(5) {
  animation-delay: 0.6s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(6), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(6) {
  animation-delay: 0.62s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(7), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(7) {
  animation-delay: 0.64s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(8), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(8) {
  animation-delay: 0.66s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(9), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(9) {
  animation-delay: 0.68s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(10), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(10) {
  animation-delay: 0.7s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(11), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(11) {
  animation-delay: 0.72s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(12), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(12) {
  animation-delay: 0.74s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(13), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(13) {
  animation-delay: 0.76s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(14), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(14) {
  animation-delay: 0.78s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(15), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(15) {
  animation-delay: 0.8s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(16), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(16) {
  animation-delay: 0.82s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(17), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(17) {
  animation-delay: 0.84s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(18), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(18) {
  animation-delay: 0.86s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(19), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(19) {
  animation-delay: 0.88s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(20), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(20) {
  animation-delay: 0.9s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(21), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(21) {
  animation-delay: 0.92s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(22), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(22) {
  animation-delay: 0.94s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(23), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(23) {
  animation-delay: 0.96s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(24), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(24) {
  animation-delay: 0.98s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(25), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(25) {
  animation-delay: 1s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(26), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(26) {
  animation-delay: 1.02s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(27), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(27) {
  animation-delay: 1.04s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(28), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(28) {
  animation-delay: 1.06s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(29), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(29) {
  animation-delay: 1.08s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(30), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(30) {
  animation-delay: 1.1s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(31), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(31) {
  animation-delay: 1.12s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(32), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(32) {
  animation-delay: 1.14s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(33), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(33) {
  animation-delay: 1.16s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(34), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(34) {
  animation-delay: 1.18s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(35), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(35) {
  animation-delay: 1.2s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(36), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(36) {
  animation-delay: 1.22s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(37), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(37) {
  animation-delay: 1.24s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(38), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(38) {
  animation-delay: 1.26s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(39), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(39) {
  animation-delay: 1.28s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(40), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(40) {
  animation-delay: 1.3s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(41), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(41) {
  animation-delay: 1.32s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(42), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(42) {
  animation-delay: 1.34s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(43), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(43) {
  animation-delay: 1.36s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(44), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(44) {
  animation-delay: 1.38s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(45), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(45) {
  animation-delay: 1.4s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(46), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(46) {
  animation-delay: 1.42s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(47), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(47) {
  animation-delay: 1.44s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(48), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(48) {
  animation-delay: 1.46s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(49), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(49) {
  animation-delay: 1.48s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(50), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(50) {
  animation-delay: 1.5s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(51), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(51) {
  animation-delay: 1.52s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(52), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(52) {
  animation-delay: 1.54s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(53), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(53) {
  animation-delay: 1.56s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(54), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(54) {
  animation-delay: 1.58s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(55), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(55) {
  animation-delay: 1.6s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(56), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(56) {
  animation-delay: 1.62s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(57), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(57) {
  animation-delay: 1.64s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(58), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(58) {
  animation-delay: 1.66s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(59), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(59) {
  animation-delay: 1.68s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(60), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(60) {
  animation-delay: 1.7s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(61), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(61) {
  animation-delay: 1.72s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(62), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(62) {
  animation-delay: 1.74s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(63), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(63) {
  animation-delay: 1.76s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(64), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(64) {
  animation-delay: 1.78s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(65), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(65) {
  animation-delay: 1.8s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(66), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(66) {
  animation-delay: 1.82s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(67), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(67) {
  animation-delay: 1.84s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(68), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(68) {
  animation-delay: 1.86s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(69), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(69) {
  animation-delay: 1.88s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(70), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(70) {
  animation-delay: 1.9s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(71), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(71) {
  animation-delay: 1.92s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(72), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(72) {
  animation-delay: 1.94s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(73), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(73) {
  animation-delay: 1.96s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(74), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(74) {
  animation-delay: 1.98s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(75), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(75) {
  animation-delay: 2s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(76), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(76) {
  animation-delay: 2.02s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(77), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(77) {
  animation-delay: 2.04s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(78), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(78) {
  animation-delay: 2.06s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(79), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(79) {
  animation-delay: 2.08s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(80), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(80) {
  animation-delay: 2.1s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(81), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(81) {
  animation-delay: 2.12s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(82), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(82) {
  animation-delay: 2.14s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(83), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(83) {
  animation-delay: 2.16s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(84), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(84) {
  animation-delay: 2.18s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(85), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(85) {
  animation-delay: 2.2s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(86), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(86) {
  animation-delay: 2.22s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(87), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(87) {
  animation-delay: 2.24s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(88), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(88) {
  animation-delay: 2.26s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(89), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(89) {
  animation-delay: 2.28s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(90), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(90) {
  animation-delay: 2.3s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(91), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(91) {
  animation-delay: 2.32s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(92), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(92) {
  animation-delay: 2.34s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(93), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(93) {
  animation-delay: 2.36s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(94), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(94) {
  animation-delay: 2.38s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(95), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(95) {
  animation-delay: 2.4s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(96), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(96) {
  animation-delay: 2.42s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(97), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(97) {
  animation-delay: 2.44s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(98), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(98) {
  animation-delay: 2.46s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(99), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(99) {
  animation-delay: 2.48s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(100), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(100) {
  animation-delay: 2.5s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(101), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(101) {
  animation-delay: 2.52s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(102), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(102) {
  animation-delay: 2.54s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(103), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(103) {
  animation-delay: 2.56s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(104), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(104) {
  animation-delay: 2.58s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(105), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(105) {
  animation-delay: 2.6s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(106), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(106) {
  animation-delay: 2.62s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(107), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(107) {
  animation-delay: 2.64s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(108), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(108) {
  animation-delay: 2.66s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(109), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(109) {
  animation-delay: 2.68s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(110), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(110) {
  animation-delay: 2.7s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(111), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(111) {
  animation-delay: 2.72s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(112), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(112) {
  animation-delay: 2.74s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(113), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(113) {
  animation-delay: 2.76s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(114), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(114) {
  animation-delay: 2.78s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(115), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(115) {
  animation-delay: 2.8s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(116), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(116) {
  animation-delay: 2.82s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(117), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(117) {
  animation-delay: 2.84s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(118), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(118) {
  animation-delay: 2.86s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(119), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(119) {
  animation-delay: 2.88s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(120), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(120) {
  animation-delay: 2.9s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(121), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(121) {
  animation-delay: 2.92s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(122), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(122) {
  animation-delay: 2.94s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(123), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(123) {
  animation-delay: 2.96s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(124), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(124) {
  animation-delay: 2.98s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(125), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(125) {
  animation-delay: 3s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(126), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(126) {
  animation-delay: 3.02s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(127), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(127) {
  animation-delay: 3.04s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(128), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(128) {
  animation-delay: 3.06s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(129), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(129) {
  animation-delay: 3.08s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(130), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(130) {
  animation-delay: 3.1s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(131), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(131) {
  animation-delay: 3.12s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(132), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(132) {
  animation-delay: 3.14s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(133), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(133) {
  animation-delay: 3.16s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(134), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(134) {
  animation-delay: 3.18s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(135), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(135) {
  animation-delay: 3.2s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(136), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(136) {
  animation-delay: 3.22s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(137), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(137) {
  animation-delay: 3.24s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(138), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(138) {
  animation-delay: 3.26s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(139), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(139) {
  animation-delay: 3.28s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(140), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(140) {
  animation-delay: 3.3s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(141), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(141) {
  animation-delay: 3.32s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(142), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(142) {
  animation-delay: 3.34s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(143), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(143) {
  animation-delay: 3.36s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(144), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(144) {
  animation-delay: 3.38s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(145), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(145) {
  animation-delay: 3.4s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(146), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(146) {
  animation-delay: 3.42s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(147), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(147) {
  animation-delay: 3.44s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(148), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(148) {
  animation-delay: 3.46s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(149), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(149) {
  animation-delay: 3.48s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(150), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(150) {
  animation-delay: 3.5s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(151), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(151) {
  animation-delay: 3.52s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(152), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(152) {
  animation-delay: 3.54s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(153), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(153) {
  animation-delay: 3.56s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(154), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(154) {
  animation-delay: 3.58s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(155), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(155) {
  animation-delay: 3.6s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(156), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(156) {
  animation-delay: 3.62s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(157), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(157) {
  animation-delay: 3.64s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(158), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(158) {
  animation-delay: 3.66s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(159), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(159) {
  animation-delay: 3.68s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(160), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(160) {
  animation-delay: 3.7s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(161), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(161) {
  animation-delay: 3.72s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(162), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(162) {
  animation-delay: 3.74s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(163), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(163) {
  animation-delay: 3.76s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(164), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(164) {
  animation-delay: 3.78s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(165), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(165) {
  animation-delay: 3.8s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(166), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(166) {
  animation-delay: 3.82s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(167), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(167) {
  animation-delay: 3.84s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(168), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(168) {
  animation-delay: 3.86s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(169), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(169) {
  animation-delay: 3.88s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(170), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(170) {
  animation-delay: 3.9s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(171), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(171) {
  animation-delay: 3.92s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(172), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(172) {
  animation-delay: 3.94s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(173), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(173) {
  animation-delay: 3.96s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(174), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(174) {
  animation-delay: 3.98s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(175), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(175) {
  animation-delay: 4s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(176), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(176) {
  animation-delay: 4.02s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(177), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(177) {
  animation-delay: 4.04s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(178), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(178) {
  animation-delay: 4.06s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(179), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(179) {
  animation-delay: 4.08s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(180), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(180) {
  animation-delay: 4.1s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(181), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(181) {
  animation-delay: 4.12s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(182), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(182) {
  animation-delay: 4.14s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(183), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(183) {
  animation-delay: 4.16s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(184), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(184) {
  animation-delay: 4.18s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(185), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(185) {
  animation-delay: 4.2s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(186), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(186) {
  animation-delay: 4.22s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(187), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(187) {
  animation-delay: 4.24s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(188), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(188) {
  animation-delay: 4.26s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(189), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(189) {
  animation-delay: 4.28s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(190), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(190) {
  animation-delay: 4.3s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(191), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(191) {
  animation-delay: 4.32s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(192), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(192) {
  animation-delay: 4.34s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(193), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(193) {
  animation-delay: 4.36s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(194), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(194) {
  animation-delay: 4.38s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(195), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(195) {
  animation-delay: 4.4s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(196), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(196) {
  animation-delay: 4.42s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(197), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(197) {
  animation-delay: 4.44s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(198), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(198) {
  animation-delay: 4.46s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(199), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(199) {
  animation-delay: 4.48s !important;
}
.fade-appear-done .parallax--element.el_in_viewport .word:nth-of-type(200), .fade-enter-done .parallax--element.el_in_viewport .word:nth-of-type(200) {
  animation-delay: 4.5s !important;
}
.fade-appear-done .text-container, .fade-enter-done .text-container, .fade-appear-done .skill-name, .fade-enter-done .skill-name {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.fade-appear-done .text-container, .fade-enter-done .text-container {
  transition-delay: 0.9s;
}
.fade-appear-done .form-group, .fade-enter-done .form-group {
  transform: scale(1);
}

.fade-appear-done, .fade-enter-done {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.fade-exit {
  opacity: 0;
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-exit .progress-bar {
  transform: translateX(100vw);
}
.fade-exit .parallax--element {
  transform: translateX(100vw);
}
.fade-exit .parallax--element.el_in_viewport {
  transform: translateX(100vw);
}
.fade-exit .text-container, .fade-exit .skill-name {
  transform: translateX(200vw);
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.fade-exit .form-group {
  transform: translateX(100vw);
}

.fade-enter-active .row > div:nth-of-type(1) .parallax--element:nth-child(1), .fade-enter-done .row > div:nth-of-type(1) .parallax--element:nth-child(1) {
  transition: all 0.6s cubic-bezier(1, 0.79, 0.42, 1.31) 0.3s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(1) .parallax--element:nth-child(2), .fade-enter-done .row > div:nth-of-type(1) .parallax--element:nth-child(2) {
  transition: all 0.6s cubic-bezier(1, 0.61, 0.28, 1.29) 0.3s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(1) .parallax--element:nth-child(3), .fade-enter-done .row > div:nth-of-type(1) .parallax--element:nth-child(3) {
  transition: all 0.6s cubic-bezier(1, 0.85, 0.33, 1.4) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(1) .parallax--element:nth-child(4), .fade-enter-done .row > div:nth-of-type(1) .parallax--element:nth-child(4) {
  transition: all 0.6s cubic-bezier(1, 0.61, 0.41, 1.31) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(1) .parallax--element:nth-child(5), .fade-enter-done .row > div:nth-of-type(1) .parallax--element:nth-child(5) {
  transition: all 0.6s cubic-bezier(1, 0.63, 0.45, 1.28) 0.2s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(1) .parallax--element:nth-child(6), .fade-enter-done .row > div:nth-of-type(1) .parallax--element:nth-child(6) {
  transition: all 0.6s cubic-bezier(1, 0.91, 0.36, 1.42) 0.3s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(1) .parallax--element:nth-child(7), .fade-enter-done .row > div:nth-of-type(1) .parallax--element:nth-child(7) {
  transition: all 0.6s cubic-bezier(1, 0.85, 0.34, 1.32) 0.2s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(1) .parallax--element:nth-child(8), .fade-enter-done .row > div:nth-of-type(1) .parallax--element:nth-child(8) {
  transition: all 0.6s cubic-bezier(1, 0.66, 0.3, 1.34) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(1) .parallax--element:nth-child(9), .fade-enter-done .row > div:nth-of-type(1) .parallax--element:nth-child(9) {
  transition: all 0.6s cubic-bezier(1, 0.95, 0.47, 1.42) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(1) .parallax--element:nth-child(10), .fade-enter-done .row > div:nth-of-type(1) .parallax--element:nth-child(10) {
  transition: all 0.6s cubic-bezier(1, 0.93, 0.33, 1.31) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .skill-name:nth-of-type(1), .fade-enter-done .skill-name:nth-of-type(1) {
  transition: all 0.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(2), .fade-enter-done .skill-name:nth-of-type(2) {
  transition: all 0.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(3), .fade-enter-done .skill-name:nth-of-type(3) {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(4), .fade-enter-done .skill-name:nth-of-type(4) {
  transition: all 0.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(5), .fade-enter-done .skill-name:nth-of-type(5) {
  transition: all 0.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(6), .fade-enter-done .skill-name:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(7), .fade-enter-done .skill-name:nth-of-type(7) {
  transition: all 0.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(8), .fade-enter-done .skill-name:nth-of-type(8) {
  transition: all 0.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(9), .fade-enter-done .skill-name:nth-of-type(9) {
  transition: all 0.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(10), .fade-enter-done .skill-name:nth-of-type(10) {
  transition: all 1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(11), .fade-enter-done .skill-name:nth-of-type(11) {
  transition: all 1.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(12), .fade-enter-done .skill-name:nth-of-type(12) {
  transition: all 1.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(13), .fade-enter-done .skill-name:nth-of-type(13) {
  transition: all 1.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(14), .fade-enter-done .skill-name:nth-of-type(14) {
  transition: all 1.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(15), .fade-enter-done .skill-name:nth-of-type(15) {
  transition: all 1.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(16), .fade-enter-done .skill-name:nth-of-type(16) {
  transition: all 1.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(17), .fade-enter-done .skill-name:nth-of-type(17) {
  transition: all 1.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(18), .fade-enter-done .skill-name:nth-of-type(18) {
  transition: all 1.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(19), .fade-enter-done .skill-name:nth-of-type(19) {
  transition: all 1.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(20), .fade-enter-done .skill-name:nth-of-type(20) {
  transition: all 2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .form-group:nth-of-type(1), .fade-enter-done .form-group:nth-of-type(1) {
  transition: all 0.6s cubic-bezier(1, 0.76, 0.41, 1.42) 0.75s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(2), .fade-enter-done .form-group:nth-of-type(2) {
  transition: all 0.6s cubic-bezier(1, 0.58, 0.5, 1.44) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(3), .fade-enter-done .form-group:nth-of-type(3) {
  transition: all 0.6s cubic-bezier(1, 0.56, 0.25, 1.27) 0.85s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(4), .fade-enter-done .form-group:nth-of-type(4) {
  transition: all 0.6s cubic-bezier(1, 0.72, 0.28, 1.3) 0.65s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(5), .fade-enter-done .form-group:nth-of-type(5) {
  transition: all 0.6s cubic-bezier(1, 0.64, 0.5, 1.29) 0.75s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(6), .fade-enter-done .form-group:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(1, 0.9, 0.48, 1.25) 0.75s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(7), .fade-enter-done .form-group:nth-of-type(7) {
  transition: all 0.6s cubic-bezier(1, 0.89, 0.42, 1.35) 0.75s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(8), .fade-enter-done .form-group:nth-of-type(8) {
  transition: all 0.6s cubic-bezier(1, 0.8, 0.34, 1.34) 0.85s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(9), .fade-enter-done .form-group:nth-of-type(9) {
  transition: all 0.6s cubic-bezier(1, 0.97, 0.25, 1.35) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(10), .fade-enter-done .form-group:nth-of-type(10) {
  transition: all 0.6s cubic-bezier(1, 0.81, 0.49, 1.44) 0.85s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar, .fade-enter-done .progress:nth-of-type(1) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar:after, .fade-enter-done .progress:nth-of-type(1) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar, .fade-enter-done .progress:nth-of-type(2) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar:after, .fade-enter-done .progress:nth-of-type(2) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar, .fade-enter-done .progress:nth-of-type(3) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar:after, .fade-enter-done .progress:nth-of-type(3) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar, .fade-enter-done .progress:nth-of-type(4) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar:after, .fade-enter-done .progress:nth-of-type(4) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar, .fade-enter-done .progress:nth-of-type(5) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar:after, .fade-enter-done .progress:nth-of-type(5) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar, .fade-enter-done .progress:nth-of-type(6) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar:after, .fade-enter-done .progress:nth-of-type(6) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar, .fade-enter-done .progress:nth-of-type(7) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar:after, .fade-enter-done .progress:nth-of-type(7) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar, .fade-enter-done .progress:nth-of-type(8) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar:after, .fade-enter-done .progress:nth-of-type(8) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar, .fade-enter-done .progress:nth-of-type(9) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar:after, .fade-enter-done .progress:nth-of-type(9) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar, .fade-enter-done .progress:nth-of-type(10) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar:after, .fade-enter-done .progress:nth-of-type(10) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar, .fade-enter-done .progress:nth-of-type(11) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar:after, .fade-enter-done .progress:nth-of-type(11) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar, .fade-enter-done .progress:nth-of-type(12) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar:after, .fade-enter-done .progress:nth-of-type(12) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar, .fade-enter-done .progress:nth-of-type(13) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar:after, .fade-enter-done .progress:nth-of-type(13) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar, .fade-enter-done .progress:nth-of-type(14) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar:after, .fade-enter-done .progress:nth-of-type(14) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar, .fade-enter-done .progress:nth-of-type(15) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar:after, .fade-enter-done .progress:nth-of-type(15) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar, .fade-enter-done .progress:nth-of-type(16) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar:after, .fade-enter-done .progress:nth-of-type(16) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar, .fade-enter-done .progress:nth-of-type(17) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar:after, .fade-enter-done .progress:nth-of-type(17) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar, .fade-enter-done .progress:nth-of-type(18) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar:after, .fade-enter-done .progress:nth-of-type(18) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar, .fade-enter-done .progress:nth-of-type(19) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar:after, .fade-enter-done .progress:nth-of-type(19) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar, .fade-enter-done .progress:nth-of-type(20) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar:after, .fade-enter-done .progress:nth-of-type(20) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}

.fade-enter-active .row > div:nth-of-type(2) .parallax--element:nth-child(1), .fade-enter-done .row > div:nth-of-type(2) .parallax--element:nth-child(1) {
  transition: all 0.6s cubic-bezier(1, 0.97, 0.29, 1.37) 0.3s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(2) .parallax--element:nth-child(2), .fade-enter-done .row > div:nth-of-type(2) .parallax--element:nth-child(2) {
  transition: all 0.6s cubic-bezier(1, 0.55, 0.37, 1.25) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(2) .parallax--element:nth-child(3), .fade-enter-done .row > div:nth-of-type(2) .parallax--element:nth-child(3) {
  transition: all 0.6s cubic-bezier(1, 0.83, 0.49, 1.42) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(2) .parallax--element:nth-child(4), .fade-enter-done .row > div:nth-of-type(2) .parallax--element:nth-child(4) {
  transition: all 0.6s cubic-bezier(1, 0.8, 0.37, 1.42) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(2) .parallax--element:nth-child(5), .fade-enter-done .row > div:nth-of-type(2) .parallax--element:nth-child(5) {
  transition: all 0.6s cubic-bezier(1, 0.66, 0.29, 1.29) 0.2s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(2) .parallax--element:nth-child(6), .fade-enter-done .row > div:nth-of-type(2) .parallax--element:nth-child(6) {
  transition: all 0.6s cubic-bezier(1, 0.51, 0.47, 1.41) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(2) .parallax--element:nth-child(7), .fade-enter-done .row > div:nth-of-type(2) .parallax--element:nth-child(7) {
  transition: all 0.6s cubic-bezier(1, 0.72, 0.27, 1.26) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(2) .parallax--element:nth-child(8), .fade-enter-done .row > div:nth-of-type(2) .parallax--element:nth-child(8) {
  transition: all 0.6s cubic-bezier(1, 0.88, 0.5, 1.45) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(2) .parallax--element:nth-child(9), .fade-enter-done .row > div:nth-of-type(2) .parallax--element:nth-child(9) {
  transition: all 0.6s cubic-bezier(1, 0.95, 0.29, 1.39) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(2) .parallax--element:nth-child(10), .fade-enter-done .row > div:nth-of-type(2) .parallax--element:nth-child(10) {
  transition: all 0.6s cubic-bezier(1, 0.54, 0.45, 1.35) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .skill-name:nth-of-type(1), .fade-enter-done .skill-name:nth-of-type(1) {
  transition: all 0.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(2), .fade-enter-done .skill-name:nth-of-type(2) {
  transition: all 0.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(3), .fade-enter-done .skill-name:nth-of-type(3) {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(4), .fade-enter-done .skill-name:nth-of-type(4) {
  transition: all 0.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(5), .fade-enter-done .skill-name:nth-of-type(5) {
  transition: all 0.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(6), .fade-enter-done .skill-name:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(7), .fade-enter-done .skill-name:nth-of-type(7) {
  transition: all 0.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(8), .fade-enter-done .skill-name:nth-of-type(8) {
  transition: all 0.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(9), .fade-enter-done .skill-name:nth-of-type(9) {
  transition: all 0.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(10), .fade-enter-done .skill-name:nth-of-type(10) {
  transition: all 1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(11), .fade-enter-done .skill-name:nth-of-type(11) {
  transition: all 1.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(12), .fade-enter-done .skill-name:nth-of-type(12) {
  transition: all 1.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(13), .fade-enter-done .skill-name:nth-of-type(13) {
  transition: all 1.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(14), .fade-enter-done .skill-name:nth-of-type(14) {
  transition: all 1.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(15), .fade-enter-done .skill-name:nth-of-type(15) {
  transition: all 1.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(16), .fade-enter-done .skill-name:nth-of-type(16) {
  transition: all 1.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(17), .fade-enter-done .skill-name:nth-of-type(17) {
  transition: all 1.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(18), .fade-enter-done .skill-name:nth-of-type(18) {
  transition: all 1.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(19), .fade-enter-done .skill-name:nth-of-type(19) {
  transition: all 1.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(20), .fade-enter-done .skill-name:nth-of-type(20) {
  transition: all 2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .form-group:nth-of-type(1), .fade-enter-done .form-group:nth-of-type(1) {
  transition: all 0.6s cubic-bezier(1, 0.72, 0.45, 1.26) 0.75s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(2), .fade-enter-done .form-group:nth-of-type(2) {
  transition: all 0.6s cubic-bezier(1, 0.57, 0.43, 1.43) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(3), .fade-enter-done .form-group:nth-of-type(3) {
  transition: all 0.6s cubic-bezier(1, 0.7, 0.35, 1.29) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(4), .fade-enter-done .form-group:nth-of-type(4) {
  transition: all 0.6s cubic-bezier(1, 0.72, 0.42, 1.41) 0.65s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(5), .fade-enter-done .form-group:nth-of-type(5) {
  transition: all 0.6s cubic-bezier(1, 0.97, 0.34, 1.32) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(6), .fade-enter-done .form-group:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(1, 0.7, 0.38, 1.44) 0.85s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(7), .fade-enter-done .form-group:nth-of-type(7) {
  transition: all 0.6s cubic-bezier(1, 0.55, 0.44, 1.35) 0.75s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(8), .fade-enter-done .form-group:nth-of-type(8) {
  transition: all 0.6s cubic-bezier(1, 0.64, 0.32, 1.39) 0.85s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(9), .fade-enter-done .form-group:nth-of-type(9) {
  transition: all 0.6s cubic-bezier(1, 0.59, 0.41, 1.27) 0.65s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(10), .fade-enter-done .form-group:nth-of-type(10) {
  transition: all 0.6s cubic-bezier(1, 0.93, 0.45, 1.44) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar, .fade-enter-done .progress:nth-of-type(1) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar:after, .fade-enter-done .progress:nth-of-type(1) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar, .fade-enter-done .progress:nth-of-type(2) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar:after, .fade-enter-done .progress:nth-of-type(2) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar, .fade-enter-done .progress:nth-of-type(3) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar:after, .fade-enter-done .progress:nth-of-type(3) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar, .fade-enter-done .progress:nth-of-type(4) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar:after, .fade-enter-done .progress:nth-of-type(4) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar, .fade-enter-done .progress:nth-of-type(5) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar:after, .fade-enter-done .progress:nth-of-type(5) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar, .fade-enter-done .progress:nth-of-type(6) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar:after, .fade-enter-done .progress:nth-of-type(6) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar, .fade-enter-done .progress:nth-of-type(7) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar:after, .fade-enter-done .progress:nth-of-type(7) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar, .fade-enter-done .progress:nth-of-type(8) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar:after, .fade-enter-done .progress:nth-of-type(8) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar, .fade-enter-done .progress:nth-of-type(9) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar:after, .fade-enter-done .progress:nth-of-type(9) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar, .fade-enter-done .progress:nth-of-type(10) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar:after, .fade-enter-done .progress:nth-of-type(10) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar, .fade-enter-done .progress:nth-of-type(11) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar:after, .fade-enter-done .progress:nth-of-type(11) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar, .fade-enter-done .progress:nth-of-type(12) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar:after, .fade-enter-done .progress:nth-of-type(12) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar, .fade-enter-done .progress:nth-of-type(13) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar:after, .fade-enter-done .progress:nth-of-type(13) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar, .fade-enter-done .progress:nth-of-type(14) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar:after, .fade-enter-done .progress:nth-of-type(14) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar, .fade-enter-done .progress:nth-of-type(15) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar:after, .fade-enter-done .progress:nth-of-type(15) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar, .fade-enter-done .progress:nth-of-type(16) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar:after, .fade-enter-done .progress:nth-of-type(16) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar, .fade-enter-done .progress:nth-of-type(17) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar:after, .fade-enter-done .progress:nth-of-type(17) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar, .fade-enter-done .progress:nth-of-type(18) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar:after, .fade-enter-done .progress:nth-of-type(18) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar, .fade-enter-done .progress:nth-of-type(19) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar:after, .fade-enter-done .progress:nth-of-type(19) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar, .fade-enter-done .progress:nth-of-type(20) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar:after, .fade-enter-done .progress:nth-of-type(20) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}

.fade-enter-active .row > div:nth-of-type(3) .parallax--element:nth-child(1), .fade-enter-done .row > div:nth-of-type(3) .parallax--element:nth-child(1) {
  transition: all 0.6s cubic-bezier(1, 0.95, 0.32, 1.38) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(3) .parallax--element:nth-child(2), .fade-enter-done .row > div:nth-of-type(3) .parallax--element:nth-child(2) {
  transition: all 0.6s cubic-bezier(1, 0.65, 0.42, 1.26) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(3) .parallax--element:nth-child(3), .fade-enter-done .row > div:nth-of-type(3) .parallax--element:nth-child(3) {
  transition: all 0.6s cubic-bezier(1, 0.9, 0.3, 1.41) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(3) .parallax--element:nth-child(4), .fade-enter-done .row > div:nth-of-type(3) .parallax--element:nth-child(4) {
  transition: all 0.6s cubic-bezier(1, 0.83, 0.32, 1.41) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(3) .parallax--element:nth-child(5), .fade-enter-done .row > div:nth-of-type(3) .parallax--element:nth-child(5) {
  transition: all 0.6s cubic-bezier(1, 0.93, 0.35, 1.35) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(3) .parallax--element:nth-child(6), .fade-enter-done .row > div:nth-of-type(3) .parallax--element:nth-child(6) {
  transition: all 0.6s cubic-bezier(1, 0.85, 0.5, 1.26) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(3) .parallax--element:nth-child(7), .fade-enter-done .row > div:nth-of-type(3) .parallax--element:nth-child(7) {
  transition: all 0.6s cubic-bezier(1, 0.53, 0.37, 1.45) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(3) .parallax--element:nth-child(8), .fade-enter-done .row > div:nth-of-type(3) .parallax--element:nth-child(8) {
  transition: all 0.6s cubic-bezier(1, 0.84, 0.44, 1.44) 0.3s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(3) .parallax--element:nth-child(9), .fade-enter-done .row > div:nth-of-type(3) .parallax--element:nth-child(9) {
  transition: all 0.6s cubic-bezier(1, 0.6, 0.47, 1.36) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(3) .parallax--element:nth-child(10), .fade-enter-done .row > div:nth-of-type(3) .parallax--element:nth-child(10) {
  transition: all 0.6s cubic-bezier(1, 0.8, 0.43, 1.3) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .skill-name:nth-of-type(1), .fade-enter-done .skill-name:nth-of-type(1) {
  transition: all 0.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(2), .fade-enter-done .skill-name:nth-of-type(2) {
  transition: all 0.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(3), .fade-enter-done .skill-name:nth-of-type(3) {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(4), .fade-enter-done .skill-name:nth-of-type(4) {
  transition: all 0.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(5), .fade-enter-done .skill-name:nth-of-type(5) {
  transition: all 0.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(6), .fade-enter-done .skill-name:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(7), .fade-enter-done .skill-name:nth-of-type(7) {
  transition: all 0.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(8), .fade-enter-done .skill-name:nth-of-type(8) {
  transition: all 0.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(9), .fade-enter-done .skill-name:nth-of-type(9) {
  transition: all 0.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(10), .fade-enter-done .skill-name:nth-of-type(10) {
  transition: all 1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(11), .fade-enter-done .skill-name:nth-of-type(11) {
  transition: all 1.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(12), .fade-enter-done .skill-name:nth-of-type(12) {
  transition: all 1.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(13), .fade-enter-done .skill-name:nth-of-type(13) {
  transition: all 1.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(14), .fade-enter-done .skill-name:nth-of-type(14) {
  transition: all 1.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(15), .fade-enter-done .skill-name:nth-of-type(15) {
  transition: all 1.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(16), .fade-enter-done .skill-name:nth-of-type(16) {
  transition: all 1.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(17), .fade-enter-done .skill-name:nth-of-type(17) {
  transition: all 1.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(18), .fade-enter-done .skill-name:nth-of-type(18) {
  transition: all 1.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(19), .fade-enter-done .skill-name:nth-of-type(19) {
  transition: all 1.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(20), .fade-enter-done .skill-name:nth-of-type(20) {
  transition: all 2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .form-group:nth-of-type(1), .fade-enter-done .form-group:nth-of-type(1) {
  transition: all 0.6s cubic-bezier(1, 0.58, 0.43, 1.25) 0.85s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(2), .fade-enter-done .form-group:nth-of-type(2) {
  transition: all 0.6s cubic-bezier(1, 0.57, 0.36, 1.44) 0.75s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(3), .fade-enter-done .form-group:nth-of-type(3) {
  transition: all 0.6s cubic-bezier(1, 0.98, 0.26, 1.38) 0.85s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(4), .fade-enter-done .form-group:nth-of-type(4) {
  transition: all 0.6s cubic-bezier(1, 0.99, 0.43, 1.34) 0.65s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(5), .fade-enter-done .form-group:nth-of-type(5) {
  transition: all 0.6s cubic-bezier(1, 0.86, 0.43, 1.26) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(6), .fade-enter-done .form-group:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(1, 0.9, 0.48, 1.39) 0.65s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(7), .fade-enter-done .form-group:nth-of-type(7) {
  transition: all 0.6s cubic-bezier(1, 0.58, 0.42, 1.29) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(8), .fade-enter-done .form-group:nth-of-type(8) {
  transition: all 0.6s cubic-bezier(1, 0.96, 0.47, 1.26) 0.75s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(9), .fade-enter-done .form-group:nth-of-type(9) {
  transition: all 0.6s cubic-bezier(1, 0.5, 0.37, 1.32) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(10), .fade-enter-done .form-group:nth-of-type(10) {
  transition: all 0.6s cubic-bezier(1, 0.74, 0.3, 1.45) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar, .fade-enter-done .progress:nth-of-type(1) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar:after, .fade-enter-done .progress:nth-of-type(1) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar, .fade-enter-done .progress:nth-of-type(2) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar:after, .fade-enter-done .progress:nth-of-type(2) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar, .fade-enter-done .progress:nth-of-type(3) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar:after, .fade-enter-done .progress:nth-of-type(3) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar, .fade-enter-done .progress:nth-of-type(4) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar:after, .fade-enter-done .progress:nth-of-type(4) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar, .fade-enter-done .progress:nth-of-type(5) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar:after, .fade-enter-done .progress:nth-of-type(5) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar, .fade-enter-done .progress:nth-of-type(6) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar:after, .fade-enter-done .progress:nth-of-type(6) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar, .fade-enter-done .progress:nth-of-type(7) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar:after, .fade-enter-done .progress:nth-of-type(7) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar, .fade-enter-done .progress:nth-of-type(8) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar:after, .fade-enter-done .progress:nth-of-type(8) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar, .fade-enter-done .progress:nth-of-type(9) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar:after, .fade-enter-done .progress:nth-of-type(9) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar, .fade-enter-done .progress:nth-of-type(10) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar:after, .fade-enter-done .progress:nth-of-type(10) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar, .fade-enter-done .progress:nth-of-type(11) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar:after, .fade-enter-done .progress:nth-of-type(11) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar, .fade-enter-done .progress:nth-of-type(12) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar:after, .fade-enter-done .progress:nth-of-type(12) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar, .fade-enter-done .progress:nth-of-type(13) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar:after, .fade-enter-done .progress:nth-of-type(13) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar, .fade-enter-done .progress:nth-of-type(14) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar:after, .fade-enter-done .progress:nth-of-type(14) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar, .fade-enter-done .progress:nth-of-type(15) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar:after, .fade-enter-done .progress:nth-of-type(15) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar, .fade-enter-done .progress:nth-of-type(16) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar:after, .fade-enter-done .progress:nth-of-type(16) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar, .fade-enter-done .progress:nth-of-type(17) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar:after, .fade-enter-done .progress:nth-of-type(17) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar, .fade-enter-done .progress:nth-of-type(18) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar:after, .fade-enter-done .progress:nth-of-type(18) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar, .fade-enter-done .progress:nth-of-type(19) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar:after, .fade-enter-done .progress:nth-of-type(19) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar, .fade-enter-done .progress:nth-of-type(20) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar:after, .fade-enter-done .progress:nth-of-type(20) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}

.fade-enter-active .row > div:nth-of-type(4) .parallax--element:nth-child(1), .fade-enter-done .row > div:nth-of-type(4) .parallax--element:nth-child(1) {
  transition: all 0.6s cubic-bezier(1, 0.92, 0.41, 1.34) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(4) .parallax--element:nth-child(2), .fade-enter-done .row > div:nth-of-type(4) .parallax--element:nth-child(2) {
  transition: all 0.6s cubic-bezier(1, 0.83, 0.34, 1.33) 0.3s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(4) .parallax--element:nth-child(3), .fade-enter-done .row > div:nth-of-type(4) .parallax--element:nth-child(3) {
  transition: all 0.6s cubic-bezier(1, 0.78, 0.43, 1.29) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(4) .parallax--element:nth-child(4), .fade-enter-done .row > div:nth-of-type(4) .parallax--element:nth-child(4) {
  transition: all 0.6s cubic-bezier(1, 0.68, 0.47, 1.43) 0.2s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(4) .parallax--element:nth-child(5), .fade-enter-done .row > div:nth-of-type(4) .parallax--element:nth-child(5) {
  transition: all 0.6s cubic-bezier(1, 0.54, 0.4, 1.41) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(4) .parallax--element:nth-child(6), .fade-enter-done .row > div:nth-of-type(4) .parallax--element:nth-child(6) {
  transition: all 0.6s cubic-bezier(1, 0.52, 0.47, 1.34) 0.3s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(4) .parallax--element:nth-child(7), .fade-enter-done .row > div:nth-of-type(4) .parallax--element:nth-child(7) {
  transition: all 0.6s cubic-bezier(1, 0.66, 0.33, 1.43) 0.2s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(4) .parallax--element:nth-child(8), .fade-enter-done .row > div:nth-of-type(4) .parallax--element:nth-child(8) {
  transition: all 0.6s cubic-bezier(1, 0.81, 0.42, 1.39) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(4) .parallax--element:nth-child(9), .fade-enter-done .row > div:nth-of-type(4) .parallax--element:nth-child(9) {
  transition: all 0.6s cubic-bezier(1, 0.98, 0.37, 1.44) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(4) .parallax--element:nth-child(10), .fade-enter-done .row > div:nth-of-type(4) .parallax--element:nth-child(10) {
  transition: all 0.6s cubic-bezier(1, 0.95, 0.25, 1.32) 0.2s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .skill-name:nth-of-type(1), .fade-enter-done .skill-name:nth-of-type(1) {
  transition: all 0.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(2), .fade-enter-done .skill-name:nth-of-type(2) {
  transition: all 0.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(3), .fade-enter-done .skill-name:nth-of-type(3) {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(4), .fade-enter-done .skill-name:nth-of-type(4) {
  transition: all 0.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(5), .fade-enter-done .skill-name:nth-of-type(5) {
  transition: all 0.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(6), .fade-enter-done .skill-name:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(7), .fade-enter-done .skill-name:nth-of-type(7) {
  transition: all 0.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(8), .fade-enter-done .skill-name:nth-of-type(8) {
  transition: all 0.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(9), .fade-enter-done .skill-name:nth-of-type(9) {
  transition: all 0.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(10), .fade-enter-done .skill-name:nth-of-type(10) {
  transition: all 1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(11), .fade-enter-done .skill-name:nth-of-type(11) {
  transition: all 1.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(12), .fade-enter-done .skill-name:nth-of-type(12) {
  transition: all 1.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(13), .fade-enter-done .skill-name:nth-of-type(13) {
  transition: all 1.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(14), .fade-enter-done .skill-name:nth-of-type(14) {
  transition: all 1.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(15), .fade-enter-done .skill-name:nth-of-type(15) {
  transition: all 1.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(16), .fade-enter-done .skill-name:nth-of-type(16) {
  transition: all 1.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(17), .fade-enter-done .skill-name:nth-of-type(17) {
  transition: all 1.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(18), .fade-enter-done .skill-name:nth-of-type(18) {
  transition: all 1.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(19), .fade-enter-done .skill-name:nth-of-type(19) {
  transition: all 1.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(20), .fade-enter-done .skill-name:nth-of-type(20) {
  transition: all 2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .form-group:nth-of-type(1), .fade-enter-done .form-group:nth-of-type(1) {
  transition: all 0.6s cubic-bezier(1, 0.95, 0.35, 1.42) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(2), .fade-enter-done .form-group:nth-of-type(2) {
  transition: all 0.6s cubic-bezier(1, 0.68, 0.34, 1.32) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(3), .fade-enter-done .form-group:nth-of-type(3) {
  transition: all 0.6s cubic-bezier(1, 0.57, 0.4, 1.3) 0.85s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(4), .fade-enter-done .form-group:nth-of-type(4) {
  transition: all 0.6s cubic-bezier(1, 0.74, 0.47, 1.3) 0.75s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(5), .fade-enter-done .form-group:nth-of-type(5) {
  transition: all 0.6s cubic-bezier(1, 0.54, 0.46, 1.38) 0.75s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(6), .fade-enter-done .form-group:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(1, 0.62, 0.29, 1.33) 0.65s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(7), .fade-enter-done .form-group:nth-of-type(7) {
  transition: all 0.6s cubic-bezier(1, 0.79, 0.26, 1.44) 0.75s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(8), .fade-enter-done .form-group:nth-of-type(8) {
  transition: all 0.6s cubic-bezier(1, 0.65, 0.48, 1.41) 0.75s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(9), .fade-enter-done .form-group:nth-of-type(9) {
  transition: all 0.6s cubic-bezier(1, 0.89, 0.35, 1.41) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(10), .fade-enter-done .form-group:nth-of-type(10) {
  transition: all 0.6s cubic-bezier(1, 0.86, 0.26, 1.29) 0.75s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar, .fade-enter-done .progress:nth-of-type(1) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar:after, .fade-enter-done .progress:nth-of-type(1) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar, .fade-enter-done .progress:nth-of-type(2) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar:after, .fade-enter-done .progress:nth-of-type(2) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar, .fade-enter-done .progress:nth-of-type(3) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar:after, .fade-enter-done .progress:nth-of-type(3) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar, .fade-enter-done .progress:nth-of-type(4) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar:after, .fade-enter-done .progress:nth-of-type(4) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar, .fade-enter-done .progress:nth-of-type(5) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar:after, .fade-enter-done .progress:nth-of-type(5) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar, .fade-enter-done .progress:nth-of-type(6) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar:after, .fade-enter-done .progress:nth-of-type(6) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar, .fade-enter-done .progress:nth-of-type(7) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar:after, .fade-enter-done .progress:nth-of-type(7) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar, .fade-enter-done .progress:nth-of-type(8) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar:after, .fade-enter-done .progress:nth-of-type(8) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar, .fade-enter-done .progress:nth-of-type(9) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar:after, .fade-enter-done .progress:nth-of-type(9) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar, .fade-enter-done .progress:nth-of-type(10) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar:after, .fade-enter-done .progress:nth-of-type(10) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar, .fade-enter-done .progress:nth-of-type(11) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar:after, .fade-enter-done .progress:nth-of-type(11) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar, .fade-enter-done .progress:nth-of-type(12) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar:after, .fade-enter-done .progress:nth-of-type(12) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar, .fade-enter-done .progress:nth-of-type(13) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar:after, .fade-enter-done .progress:nth-of-type(13) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar, .fade-enter-done .progress:nth-of-type(14) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar:after, .fade-enter-done .progress:nth-of-type(14) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar, .fade-enter-done .progress:nth-of-type(15) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar:after, .fade-enter-done .progress:nth-of-type(15) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar, .fade-enter-done .progress:nth-of-type(16) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar:after, .fade-enter-done .progress:nth-of-type(16) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar, .fade-enter-done .progress:nth-of-type(17) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar:after, .fade-enter-done .progress:nth-of-type(17) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar, .fade-enter-done .progress:nth-of-type(18) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar:after, .fade-enter-done .progress:nth-of-type(18) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar, .fade-enter-done .progress:nth-of-type(19) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar:after, .fade-enter-done .progress:nth-of-type(19) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar, .fade-enter-done .progress:nth-of-type(20) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar:after, .fade-enter-done .progress:nth-of-type(20) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}

.fade-enter-active .row > div:nth-of-type(5) .parallax--element:nth-child(1), .fade-enter-done .row > div:nth-of-type(5) .parallax--element:nth-child(1) {
  transition: all 0.6s cubic-bezier(1, 0.62, 0.45, 1.26) 0.3s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(5) .parallax--element:nth-child(2), .fade-enter-done .row > div:nth-of-type(5) .parallax--element:nth-child(2) {
  transition: all 0.6s cubic-bezier(1, 0.88, 0.47, 1.4) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(5) .parallax--element:nth-child(3), .fade-enter-done .row > div:nth-of-type(5) .parallax--element:nth-child(3) {
  transition: all 0.6s cubic-bezier(1, 0.58, 0.4, 1.4) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(5) .parallax--element:nth-child(4), .fade-enter-done .row > div:nth-of-type(5) .parallax--element:nth-child(4) {
  transition: all 0.6s cubic-bezier(1, 0.9, 0.35, 1.28) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(5) .parallax--element:nth-child(5), .fade-enter-done .row > div:nth-of-type(5) .parallax--element:nth-child(5) {
  transition: all 0.6s cubic-bezier(1, 0.63, 0.25, 1.43) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(5) .parallax--element:nth-child(6), .fade-enter-done .row > div:nth-of-type(5) .parallax--element:nth-child(6) {
  transition: all 0.6s cubic-bezier(1, 0.8, 0.29, 1.37) 0.3s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(5) .parallax--element:nth-child(7), .fade-enter-done .row > div:nth-of-type(5) .parallax--element:nth-child(7) {
  transition: all 0.6s cubic-bezier(1, 0.82, 0.33, 1.33) 0.2s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(5) .parallax--element:nth-child(8), .fade-enter-done .row > div:nth-of-type(5) .parallax--element:nth-child(8) {
  transition: all 0.6s cubic-bezier(1, 0.65, 0.39, 1.31) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(5) .parallax--element:nth-child(9), .fade-enter-done .row > div:nth-of-type(5) .parallax--element:nth-child(9) {
  transition: all 0.6s cubic-bezier(1, 0.87, 0.46, 1.27) 0.2s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(5) .parallax--element:nth-child(10), .fade-enter-done .row > div:nth-of-type(5) .parallax--element:nth-child(10) {
  transition: all 0.6s cubic-bezier(1, 0.84, 0.37, 1.44) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .skill-name:nth-of-type(1), .fade-enter-done .skill-name:nth-of-type(1) {
  transition: all 0.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(2), .fade-enter-done .skill-name:nth-of-type(2) {
  transition: all 0.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(3), .fade-enter-done .skill-name:nth-of-type(3) {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(4), .fade-enter-done .skill-name:nth-of-type(4) {
  transition: all 0.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(5), .fade-enter-done .skill-name:nth-of-type(5) {
  transition: all 0.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(6), .fade-enter-done .skill-name:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(7), .fade-enter-done .skill-name:nth-of-type(7) {
  transition: all 0.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(8), .fade-enter-done .skill-name:nth-of-type(8) {
  transition: all 0.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(9), .fade-enter-done .skill-name:nth-of-type(9) {
  transition: all 0.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(10), .fade-enter-done .skill-name:nth-of-type(10) {
  transition: all 1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(11), .fade-enter-done .skill-name:nth-of-type(11) {
  transition: all 1.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(12), .fade-enter-done .skill-name:nth-of-type(12) {
  transition: all 1.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(13), .fade-enter-done .skill-name:nth-of-type(13) {
  transition: all 1.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(14), .fade-enter-done .skill-name:nth-of-type(14) {
  transition: all 1.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(15), .fade-enter-done .skill-name:nth-of-type(15) {
  transition: all 1.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(16), .fade-enter-done .skill-name:nth-of-type(16) {
  transition: all 1.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(17), .fade-enter-done .skill-name:nth-of-type(17) {
  transition: all 1.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(18), .fade-enter-done .skill-name:nth-of-type(18) {
  transition: all 1.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(19), .fade-enter-done .skill-name:nth-of-type(19) {
  transition: all 1.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(20), .fade-enter-done .skill-name:nth-of-type(20) {
  transition: all 2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .form-group:nth-of-type(1), .fade-enter-done .form-group:nth-of-type(1) {
  transition: all 0.6s cubic-bezier(1, 0.9, 0.47, 1.26) 0.65s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(2), .fade-enter-done .form-group:nth-of-type(2) {
  transition: all 0.6s cubic-bezier(1, 0.95, 0.3, 1.38) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(3), .fade-enter-done .form-group:nth-of-type(3) {
  transition: all 0.6s cubic-bezier(1, 0.51, 0.44, 1.31) 0.65s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(4), .fade-enter-done .form-group:nth-of-type(4) {
  transition: all 0.6s cubic-bezier(1, 0.94, 0.39, 1.26) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(5), .fade-enter-done .form-group:nth-of-type(5) {
  transition: all 0.6s cubic-bezier(1, 0.96, 0.45, 1.45) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(6), .fade-enter-done .form-group:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(1, 0.7, 0.41, 1.34) 0.75s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(7), .fade-enter-done .form-group:nth-of-type(7) {
  transition: all 0.6s cubic-bezier(1, 0.75, 0.32, 1.36) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(8), .fade-enter-done .form-group:nth-of-type(8) {
  transition: all 0.6s cubic-bezier(1, 0.82, 0.49, 1.4) 0.85s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(9), .fade-enter-done .form-group:nth-of-type(9) {
  transition: all 0.6s cubic-bezier(1, 0.67, 0.3, 1.44) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(10), .fade-enter-done .form-group:nth-of-type(10) {
  transition: all 0.6s cubic-bezier(1, 0.92, 0.28, 1.28) 0.65s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar, .fade-enter-done .progress:nth-of-type(1) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar:after, .fade-enter-done .progress:nth-of-type(1) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar, .fade-enter-done .progress:nth-of-type(2) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar:after, .fade-enter-done .progress:nth-of-type(2) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar, .fade-enter-done .progress:nth-of-type(3) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar:after, .fade-enter-done .progress:nth-of-type(3) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar, .fade-enter-done .progress:nth-of-type(4) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar:after, .fade-enter-done .progress:nth-of-type(4) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar, .fade-enter-done .progress:nth-of-type(5) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar:after, .fade-enter-done .progress:nth-of-type(5) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar, .fade-enter-done .progress:nth-of-type(6) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar:after, .fade-enter-done .progress:nth-of-type(6) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar, .fade-enter-done .progress:nth-of-type(7) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar:after, .fade-enter-done .progress:nth-of-type(7) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar, .fade-enter-done .progress:nth-of-type(8) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar:after, .fade-enter-done .progress:nth-of-type(8) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar, .fade-enter-done .progress:nth-of-type(9) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar:after, .fade-enter-done .progress:nth-of-type(9) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar, .fade-enter-done .progress:nth-of-type(10) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar:after, .fade-enter-done .progress:nth-of-type(10) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar, .fade-enter-done .progress:nth-of-type(11) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar:after, .fade-enter-done .progress:nth-of-type(11) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar, .fade-enter-done .progress:nth-of-type(12) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar:after, .fade-enter-done .progress:nth-of-type(12) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar, .fade-enter-done .progress:nth-of-type(13) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar:after, .fade-enter-done .progress:nth-of-type(13) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar, .fade-enter-done .progress:nth-of-type(14) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar:after, .fade-enter-done .progress:nth-of-type(14) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar, .fade-enter-done .progress:nth-of-type(15) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar:after, .fade-enter-done .progress:nth-of-type(15) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar, .fade-enter-done .progress:nth-of-type(16) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar:after, .fade-enter-done .progress:nth-of-type(16) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar, .fade-enter-done .progress:nth-of-type(17) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar:after, .fade-enter-done .progress:nth-of-type(17) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar, .fade-enter-done .progress:nth-of-type(18) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar:after, .fade-enter-done .progress:nth-of-type(18) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar, .fade-enter-done .progress:nth-of-type(19) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar:after, .fade-enter-done .progress:nth-of-type(19) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar, .fade-enter-done .progress:nth-of-type(20) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar:after, .fade-enter-done .progress:nth-of-type(20) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}

.fade-enter-active .row > div:nth-of-type(6) .parallax--element:nth-child(1), .fade-enter-done .row > div:nth-of-type(6) .parallax--element:nth-child(1) {
  transition: all 0.6s cubic-bezier(1, 0.78, 0.32, 1.38) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(6) .parallax--element:nth-child(2), .fade-enter-done .row > div:nth-of-type(6) .parallax--element:nth-child(2) {
  transition: all 0.6s cubic-bezier(1, 0.64, 0.33, 1.32) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(6) .parallax--element:nth-child(3), .fade-enter-done .row > div:nth-of-type(6) .parallax--element:nth-child(3) {
  transition: all 0.6s cubic-bezier(1, 0.84, 0.47, 1.27) 0.3s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(6) .parallax--element:nth-child(4), .fade-enter-done .row > div:nth-of-type(6) .parallax--element:nth-child(4) {
  transition: all 0.6s cubic-bezier(1, 0.75, 0.25, 1.3) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(6) .parallax--element:nth-child(5), .fade-enter-done .row > div:nth-of-type(6) .parallax--element:nth-child(5) {
  transition: all 0.6s cubic-bezier(1, 0.58, 0.29, 1.25) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(6) .parallax--element:nth-child(6), .fade-enter-done .row > div:nth-of-type(6) .parallax--element:nth-child(6) {
  transition: all 0.6s cubic-bezier(1, 0.61, 0.27, 1.26) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(6) .parallax--element:nth-child(7), .fade-enter-done .row > div:nth-of-type(6) .parallax--element:nth-child(7) {
  transition: all 0.6s cubic-bezier(1, 0.76, 0.36, 1.32) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(6) .parallax--element:nth-child(8), .fade-enter-done .row > div:nth-of-type(6) .parallax--element:nth-child(8) {
  transition: all 0.6s cubic-bezier(1, 0.57, 0.36, 1.32) 0.2s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(6) .parallax--element:nth-child(9), .fade-enter-done .row > div:nth-of-type(6) .parallax--element:nth-child(9) {
  transition: all 0.6s cubic-bezier(1, 0.93, 0.26, 1.35) 0.3s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(6) .parallax--element:nth-child(10), .fade-enter-done .row > div:nth-of-type(6) .parallax--element:nth-child(10) {
  transition: all 0.6s cubic-bezier(1, 0.83, 0.44, 1.43) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .skill-name:nth-of-type(1), .fade-enter-done .skill-name:nth-of-type(1) {
  transition: all 0.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(2), .fade-enter-done .skill-name:nth-of-type(2) {
  transition: all 0.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(3), .fade-enter-done .skill-name:nth-of-type(3) {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(4), .fade-enter-done .skill-name:nth-of-type(4) {
  transition: all 0.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(5), .fade-enter-done .skill-name:nth-of-type(5) {
  transition: all 0.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(6), .fade-enter-done .skill-name:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(7), .fade-enter-done .skill-name:nth-of-type(7) {
  transition: all 0.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(8), .fade-enter-done .skill-name:nth-of-type(8) {
  transition: all 0.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(9), .fade-enter-done .skill-name:nth-of-type(9) {
  transition: all 0.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(10), .fade-enter-done .skill-name:nth-of-type(10) {
  transition: all 1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(11), .fade-enter-done .skill-name:nth-of-type(11) {
  transition: all 1.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(12), .fade-enter-done .skill-name:nth-of-type(12) {
  transition: all 1.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(13), .fade-enter-done .skill-name:nth-of-type(13) {
  transition: all 1.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(14), .fade-enter-done .skill-name:nth-of-type(14) {
  transition: all 1.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(15), .fade-enter-done .skill-name:nth-of-type(15) {
  transition: all 1.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(16), .fade-enter-done .skill-name:nth-of-type(16) {
  transition: all 1.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(17), .fade-enter-done .skill-name:nth-of-type(17) {
  transition: all 1.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(18), .fade-enter-done .skill-name:nth-of-type(18) {
  transition: all 1.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(19), .fade-enter-done .skill-name:nth-of-type(19) {
  transition: all 1.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(20), .fade-enter-done .skill-name:nth-of-type(20) {
  transition: all 2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .form-group:nth-of-type(1), .fade-enter-done .form-group:nth-of-type(1) {
  transition: all 0.6s cubic-bezier(1, 0.89, 0.44, 1.33) 0.85s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(2), .fade-enter-done .form-group:nth-of-type(2) {
  transition: all 0.6s cubic-bezier(1, 0.68, 0.39, 1.37) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(3), .fade-enter-done .form-group:nth-of-type(3) {
  transition: all 0.6s cubic-bezier(1, 0.58, 0.38, 1.33) 0.85s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(4), .fade-enter-done .form-group:nth-of-type(4) {
  transition: all 0.6s cubic-bezier(1, 0.73, 0.25, 1.39) 0.65s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(5), .fade-enter-done .form-group:nth-of-type(5) {
  transition: all 0.6s cubic-bezier(1, 0.68, 0.47, 1.4) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(6), .fade-enter-done .form-group:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(1, 0.61, 0.41, 1.34) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(7), .fade-enter-done .form-group:nth-of-type(7) {
  transition: all 0.6s cubic-bezier(1, 0.82, 0.38, 1.43) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(8), .fade-enter-done .form-group:nth-of-type(8) {
  transition: all 0.6s cubic-bezier(1, 0.83, 0.43, 1.37) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(9), .fade-enter-done .form-group:nth-of-type(9) {
  transition: all 0.6s cubic-bezier(1, 0.61, 0.4, 1.28) 0.65s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(10), .fade-enter-done .form-group:nth-of-type(10) {
  transition: all 0.6s cubic-bezier(1, 0.9, 0.41, 1.44) 0.65s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar, .fade-enter-done .progress:nth-of-type(1) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar:after, .fade-enter-done .progress:nth-of-type(1) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar, .fade-enter-done .progress:nth-of-type(2) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar:after, .fade-enter-done .progress:nth-of-type(2) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar, .fade-enter-done .progress:nth-of-type(3) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar:after, .fade-enter-done .progress:nth-of-type(3) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar, .fade-enter-done .progress:nth-of-type(4) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar:after, .fade-enter-done .progress:nth-of-type(4) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar, .fade-enter-done .progress:nth-of-type(5) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar:after, .fade-enter-done .progress:nth-of-type(5) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar, .fade-enter-done .progress:nth-of-type(6) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar:after, .fade-enter-done .progress:nth-of-type(6) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar, .fade-enter-done .progress:nth-of-type(7) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar:after, .fade-enter-done .progress:nth-of-type(7) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar, .fade-enter-done .progress:nth-of-type(8) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar:after, .fade-enter-done .progress:nth-of-type(8) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar, .fade-enter-done .progress:nth-of-type(9) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar:after, .fade-enter-done .progress:nth-of-type(9) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar, .fade-enter-done .progress:nth-of-type(10) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar:after, .fade-enter-done .progress:nth-of-type(10) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar, .fade-enter-done .progress:nth-of-type(11) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar:after, .fade-enter-done .progress:nth-of-type(11) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar, .fade-enter-done .progress:nth-of-type(12) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar:after, .fade-enter-done .progress:nth-of-type(12) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar, .fade-enter-done .progress:nth-of-type(13) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar:after, .fade-enter-done .progress:nth-of-type(13) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar, .fade-enter-done .progress:nth-of-type(14) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar:after, .fade-enter-done .progress:nth-of-type(14) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar, .fade-enter-done .progress:nth-of-type(15) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar:after, .fade-enter-done .progress:nth-of-type(15) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar, .fade-enter-done .progress:nth-of-type(16) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar:after, .fade-enter-done .progress:nth-of-type(16) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar, .fade-enter-done .progress:nth-of-type(17) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar:after, .fade-enter-done .progress:nth-of-type(17) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar, .fade-enter-done .progress:nth-of-type(18) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar:after, .fade-enter-done .progress:nth-of-type(18) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar, .fade-enter-done .progress:nth-of-type(19) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar:after, .fade-enter-done .progress:nth-of-type(19) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar, .fade-enter-done .progress:nth-of-type(20) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar:after, .fade-enter-done .progress:nth-of-type(20) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}

.fade-enter-active .row > div:nth-of-type(7) .parallax--element:nth-child(1), .fade-enter-done .row > div:nth-of-type(7) .parallax--element:nth-child(1) {
  transition: all 0.6s cubic-bezier(1, 0.59, 0.38, 1.26) 0.3s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(7) .parallax--element:nth-child(2), .fade-enter-done .row > div:nth-of-type(7) .parallax--element:nth-child(2) {
  transition: all 0.6s cubic-bezier(1, 0.7, 0.35, 1.42) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(7) .parallax--element:nth-child(3), .fade-enter-done .row > div:nth-of-type(7) .parallax--element:nth-child(3) {
  transition: all 0.6s cubic-bezier(1, 0.63, 0.31, 1.41) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(7) .parallax--element:nth-child(4), .fade-enter-done .row > div:nth-of-type(7) .parallax--element:nth-child(4) {
  transition: all 0.6s cubic-bezier(1, 0.78, 0.4, 1.32) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(7) .parallax--element:nth-child(5), .fade-enter-done .row > div:nth-of-type(7) .parallax--element:nth-child(5) {
  transition: all 0.6s cubic-bezier(1, 0.69, 0.44, 1.39) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(7) .parallax--element:nth-child(6), .fade-enter-done .row > div:nth-of-type(7) .parallax--element:nth-child(6) {
  transition: all 0.6s cubic-bezier(1, 0.96, 0.31, 1.33) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(7) .parallax--element:nth-child(7), .fade-enter-done .row > div:nth-of-type(7) .parallax--element:nth-child(7) {
  transition: all 0.6s cubic-bezier(1, 0.85, 0.27, 1.41) 0.2s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(7) .parallax--element:nth-child(8), .fade-enter-done .row > div:nth-of-type(7) .parallax--element:nth-child(8) {
  transition: all 0.6s cubic-bezier(1, 0.68, 0.41, 1.3) 0.2s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(7) .parallax--element:nth-child(9), .fade-enter-done .row > div:nth-of-type(7) .parallax--element:nth-child(9) {
  transition: all 0.6s cubic-bezier(1, 0.91, 0.36, 1.33) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(7) .parallax--element:nth-child(10), .fade-enter-done .row > div:nth-of-type(7) .parallax--element:nth-child(10) {
  transition: all 0.6s cubic-bezier(1, 0.71, 0.44, 1.42) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .skill-name:nth-of-type(1), .fade-enter-done .skill-name:nth-of-type(1) {
  transition: all 0.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(2), .fade-enter-done .skill-name:nth-of-type(2) {
  transition: all 0.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(3), .fade-enter-done .skill-name:nth-of-type(3) {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(4), .fade-enter-done .skill-name:nth-of-type(4) {
  transition: all 0.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(5), .fade-enter-done .skill-name:nth-of-type(5) {
  transition: all 0.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(6), .fade-enter-done .skill-name:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(7), .fade-enter-done .skill-name:nth-of-type(7) {
  transition: all 0.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(8), .fade-enter-done .skill-name:nth-of-type(8) {
  transition: all 0.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(9), .fade-enter-done .skill-name:nth-of-type(9) {
  transition: all 0.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(10), .fade-enter-done .skill-name:nth-of-type(10) {
  transition: all 1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(11), .fade-enter-done .skill-name:nth-of-type(11) {
  transition: all 1.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(12), .fade-enter-done .skill-name:nth-of-type(12) {
  transition: all 1.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(13), .fade-enter-done .skill-name:nth-of-type(13) {
  transition: all 1.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(14), .fade-enter-done .skill-name:nth-of-type(14) {
  transition: all 1.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(15), .fade-enter-done .skill-name:nth-of-type(15) {
  transition: all 1.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(16), .fade-enter-done .skill-name:nth-of-type(16) {
  transition: all 1.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(17), .fade-enter-done .skill-name:nth-of-type(17) {
  transition: all 1.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(18), .fade-enter-done .skill-name:nth-of-type(18) {
  transition: all 1.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(19), .fade-enter-done .skill-name:nth-of-type(19) {
  transition: all 1.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(20), .fade-enter-done .skill-name:nth-of-type(20) {
  transition: all 2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .form-group:nth-of-type(1), .fade-enter-done .form-group:nth-of-type(1) {
  transition: all 0.6s cubic-bezier(1, 0.95, 0.41, 1.35) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(2), .fade-enter-done .form-group:nth-of-type(2) {
  transition: all 0.6s cubic-bezier(1, 0.56, 0.34, 1.43) 0.85s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(3), .fade-enter-done .form-group:nth-of-type(3) {
  transition: all 0.6s cubic-bezier(1, 0.73, 0.28, 1.32) 0.65s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(4), .fade-enter-done .form-group:nth-of-type(4) {
  transition: all 0.6s cubic-bezier(1, 0.69, 0.38, 1.38) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(5), .fade-enter-done .form-group:nth-of-type(5) {
  transition: all 0.6s cubic-bezier(1, 0.76, 0.43, 1.28) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(6), .fade-enter-done .form-group:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(1, 0.97, 0.49, 1.3) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(7), .fade-enter-done .form-group:nth-of-type(7) {
  transition: all 0.6s cubic-bezier(1, 0.81, 0.42, 1.44) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(8), .fade-enter-done .form-group:nth-of-type(8) {
  transition: all 0.6s cubic-bezier(1, 0.66, 0.36, 1.43) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(9), .fade-enter-done .form-group:nth-of-type(9) {
  transition: all 0.6s cubic-bezier(1, 0.9, 0.26, 1.27) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(10), .fade-enter-done .form-group:nth-of-type(10) {
  transition: all 0.6s cubic-bezier(1, 0.56, 0.33, 1.43) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar, .fade-enter-done .progress:nth-of-type(1) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar:after, .fade-enter-done .progress:nth-of-type(1) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar, .fade-enter-done .progress:nth-of-type(2) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar:after, .fade-enter-done .progress:nth-of-type(2) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar, .fade-enter-done .progress:nth-of-type(3) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar:after, .fade-enter-done .progress:nth-of-type(3) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar, .fade-enter-done .progress:nth-of-type(4) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar:after, .fade-enter-done .progress:nth-of-type(4) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar, .fade-enter-done .progress:nth-of-type(5) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar:after, .fade-enter-done .progress:nth-of-type(5) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar, .fade-enter-done .progress:nth-of-type(6) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar:after, .fade-enter-done .progress:nth-of-type(6) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar, .fade-enter-done .progress:nth-of-type(7) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar:after, .fade-enter-done .progress:nth-of-type(7) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar, .fade-enter-done .progress:nth-of-type(8) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar:after, .fade-enter-done .progress:nth-of-type(8) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar, .fade-enter-done .progress:nth-of-type(9) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar:after, .fade-enter-done .progress:nth-of-type(9) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar, .fade-enter-done .progress:nth-of-type(10) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar:after, .fade-enter-done .progress:nth-of-type(10) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar, .fade-enter-done .progress:nth-of-type(11) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar:after, .fade-enter-done .progress:nth-of-type(11) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar, .fade-enter-done .progress:nth-of-type(12) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar:after, .fade-enter-done .progress:nth-of-type(12) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar, .fade-enter-done .progress:nth-of-type(13) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar:after, .fade-enter-done .progress:nth-of-type(13) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar, .fade-enter-done .progress:nth-of-type(14) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar:after, .fade-enter-done .progress:nth-of-type(14) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar, .fade-enter-done .progress:nth-of-type(15) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar:after, .fade-enter-done .progress:nth-of-type(15) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar, .fade-enter-done .progress:nth-of-type(16) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar:after, .fade-enter-done .progress:nth-of-type(16) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar, .fade-enter-done .progress:nth-of-type(17) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar:after, .fade-enter-done .progress:nth-of-type(17) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar, .fade-enter-done .progress:nth-of-type(18) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar:after, .fade-enter-done .progress:nth-of-type(18) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar, .fade-enter-done .progress:nth-of-type(19) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar:after, .fade-enter-done .progress:nth-of-type(19) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar, .fade-enter-done .progress:nth-of-type(20) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar:after, .fade-enter-done .progress:nth-of-type(20) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}

.fade-enter-active .row > div:nth-of-type(8) .parallax--element:nth-child(1), .fade-enter-done .row > div:nth-of-type(8) .parallax--element:nth-child(1) {
  transition: all 0.6s cubic-bezier(1, 0.87, 0.43, 1.33) 0.2s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(8) .parallax--element:nth-child(2), .fade-enter-done .row > div:nth-of-type(8) .parallax--element:nth-child(2) {
  transition: all 0.6s cubic-bezier(1, 0.84, 0.38, 1.28) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(8) .parallax--element:nth-child(3), .fade-enter-done .row > div:nth-of-type(8) .parallax--element:nth-child(3) {
  transition: all 0.6s cubic-bezier(1, 0.7, 0.38, 1.45) 0.3s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(8) .parallax--element:nth-child(4), .fade-enter-done .row > div:nth-of-type(8) .parallax--element:nth-child(4) {
  transition: all 0.6s cubic-bezier(1, 0.67, 0.3, 1.35) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(8) .parallax--element:nth-child(5), .fade-enter-done .row > div:nth-of-type(8) .parallax--element:nth-child(5) {
  transition: all 0.6s cubic-bezier(1, 0.98, 0.5, 1.41) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(8) .parallax--element:nth-child(6), .fade-enter-done .row > div:nth-of-type(8) .parallax--element:nth-child(6) {
  transition: all 0.6s cubic-bezier(1, 0.68, 0.3, 1.37) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(8) .parallax--element:nth-child(7), .fade-enter-done .row > div:nth-of-type(8) .parallax--element:nth-child(7) {
  transition: all 0.6s cubic-bezier(1, 0.78, 0.33, 1.27) 0.3s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(8) .parallax--element:nth-child(8), .fade-enter-done .row > div:nth-of-type(8) .parallax--element:nth-child(8) {
  transition: all 0.6s cubic-bezier(1, 0.62, 0.49, 1.45) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(8) .parallax--element:nth-child(9), .fade-enter-done .row > div:nth-of-type(8) .parallax--element:nth-child(9) {
  transition: all 0.6s cubic-bezier(1, 0.93, 0.39, 1.39) 0.2s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(8) .parallax--element:nth-child(10), .fade-enter-done .row > div:nth-of-type(8) .parallax--element:nth-child(10) {
  transition: all 0.6s cubic-bezier(1, 0.59, 0.37, 1.33) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .skill-name:nth-of-type(1), .fade-enter-done .skill-name:nth-of-type(1) {
  transition: all 0.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(2), .fade-enter-done .skill-name:nth-of-type(2) {
  transition: all 0.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(3), .fade-enter-done .skill-name:nth-of-type(3) {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(4), .fade-enter-done .skill-name:nth-of-type(4) {
  transition: all 0.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(5), .fade-enter-done .skill-name:nth-of-type(5) {
  transition: all 0.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(6), .fade-enter-done .skill-name:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(7), .fade-enter-done .skill-name:nth-of-type(7) {
  transition: all 0.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(8), .fade-enter-done .skill-name:nth-of-type(8) {
  transition: all 0.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(9), .fade-enter-done .skill-name:nth-of-type(9) {
  transition: all 0.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(10), .fade-enter-done .skill-name:nth-of-type(10) {
  transition: all 1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(11), .fade-enter-done .skill-name:nth-of-type(11) {
  transition: all 1.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(12), .fade-enter-done .skill-name:nth-of-type(12) {
  transition: all 1.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(13), .fade-enter-done .skill-name:nth-of-type(13) {
  transition: all 1.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(14), .fade-enter-done .skill-name:nth-of-type(14) {
  transition: all 1.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(15), .fade-enter-done .skill-name:nth-of-type(15) {
  transition: all 1.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(16), .fade-enter-done .skill-name:nth-of-type(16) {
  transition: all 1.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(17), .fade-enter-done .skill-name:nth-of-type(17) {
  transition: all 1.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(18), .fade-enter-done .skill-name:nth-of-type(18) {
  transition: all 1.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(19), .fade-enter-done .skill-name:nth-of-type(19) {
  transition: all 1.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(20), .fade-enter-done .skill-name:nth-of-type(20) {
  transition: all 2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .form-group:nth-of-type(1), .fade-enter-done .form-group:nth-of-type(1) {
  transition: all 0.6s cubic-bezier(1, 0.97, 0.3, 1.42) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(2), .fade-enter-done .form-group:nth-of-type(2) {
  transition: all 0.6s cubic-bezier(1, 0.73, 0.34, 1.38) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(3), .fade-enter-done .form-group:nth-of-type(3) {
  transition: all 0.6s cubic-bezier(1, 0.93, 0.3, 1.42) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(4), .fade-enter-done .form-group:nth-of-type(4) {
  transition: all 0.6s cubic-bezier(1, 0.62, 0.3, 1.42) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(5), .fade-enter-done .form-group:nth-of-type(5) {
  transition: all 0.6s cubic-bezier(1, 0.88, 0.35, 1.34) 0.85s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(6), .fade-enter-done .form-group:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(1, 0.91, 0.48, 1.28) 0.65s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(7), .fade-enter-done .form-group:nth-of-type(7) {
  transition: all 0.6s cubic-bezier(1, 0.79, 0.39, 1.4) 0.85s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(8), .fade-enter-done .form-group:nth-of-type(8) {
  transition: all 0.6s cubic-bezier(1, 0.86, 0.43, 1.35) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(9), .fade-enter-done .form-group:nth-of-type(9) {
  transition: all 0.6s cubic-bezier(1, 0.53, 0.27, 1.45) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(10), .fade-enter-done .form-group:nth-of-type(10) {
  transition: all 0.6s cubic-bezier(1, 0.95, 0.26, 1.43) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar, .fade-enter-done .progress:nth-of-type(1) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar:after, .fade-enter-done .progress:nth-of-type(1) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar, .fade-enter-done .progress:nth-of-type(2) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar:after, .fade-enter-done .progress:nth-of-type(2) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar, .fade-enter-done .progress:nth-of-type(3) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar:after, .fade-enter-done .progress:nth-of-type(3) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar, .fade-enter-done .progress:nth-of-type(4) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar:after, .fade-enter-done .progress:nth-of-type(4) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar, .fade-enter-done .progress:nth-of-type(5) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar:after, .fade-enter-done .progress:nth-of-type(5) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar, .fade-enter-done .progress:nth-of-type(6) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar:after, .fade-enter-done .progress:nth-of-type(6) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar, .fade-enter-done .progress:nth-of-type(7) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar:after, .fade-enter-done .progress:nth-of-type(7) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar, .fade-enter-done .progress:nth-of-type(8) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar:after, .fade-enter-done .progress:nth-of-type(8) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar, .fade-enter-done .progress:nth-of-type(9) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar:after, .fade-enter-done .progress:nth-of-type(9) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar, .fade-enter-done .progress:nth-of-type(10) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar:after, .fade-enter-done .progress:nth-of-type(10) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar, .fade-enter-done .progress:nth-of-type(11) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar:after, .fade-enter-done .progress:nth-of-type(11) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar, .fade-enter-done .progress:nth-of-type(12) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar:after, .fade-enter-done .progress:nth-of-type(12) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar, .fade-enter-done .progress:nth-of-type(13) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar:after, .fade-enter-done .progress:nth-of-type(13) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar, .fade-enter-done .progress:nth-of-type(14) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar:after, .fade-enter-done .progress:nth-of-type(14) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar, .fade-enter-done .progress:nth-of-type(15) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar:after, .fade-enter-done .progress:nth-of-type(15) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar, .fade-enter-done .progress:nth-of-type(16) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar:after, .fade-enter-done .progress:nth-of-type(16) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar, .fade-enter-done .progress:nth-of-type(17) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar:after, .fade-enter-done .progress:nth-of-type(17) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar, .fade-enter-done .progress:nth-of-type(18) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar:after, .fade-enter-done .progress:nth-of-type(18) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar, .fade-enter-done .progress:nth-of-type(19) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar:after, .fade-enter-done .progress:nth-of-type(19) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar, .fade-enter-done .progress:nth-of-type(20) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar:after, .fade-enter-done .progress:nth-of-type(20) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}

.fade-enter-active .row > div:nth-of-type(9) .parallax--element:nth-child(1), .fade-enter-done .row > div:nth-of-type(9) .parallax--element:nth-child(1) {
  transition: all 0.6s cubic-bezier(1, 0.67, 0.38, 1.33) 0.3s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(9) .parallax--element:nth-child(2), .fade-enter-done .row > div:nth-of-type(9) .parallax--element:nth-child(2) {
  transition: all 0.6s cubic-bezier(1, 0.67, 0.37, 1.45) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(9) .parallax--element:nth-child(3), .fade-enter-done .row > div:nth-of-type(9) .parallax--element:nth-child(3) {
  transition: all 0.6s cubic-bezier(1, 0.98, 0.35, 1.39) 0.2s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(9) .parallax--element:nth-child(4), .fade-enter-done .row > div:nth-of-type(9) .parallax--element:nth-child(4) {
  transition: all 0.6s cubic-bezier(1, 0.78, 0.32, 1.36) 0.3s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(9) .parallax--element:nth-child(5), .fade-enter-done .row > div:nth-of-type(9) .parallax--element:nth-child(5) {
  transition: all 0.6s cubic-bezier(1, 0.73, 0.37, 1.26) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(9) .parallax--element:nth-child(6), .fade-enter-done .row > div:nth-of-type(9) .parallax--element:nth-child(6) {
  transition: all 0.6s cubic-bezier(1, 0.84, 0.32, 1.41) 0.3s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(9) .parallax--element:nth-child(7), .fade-enter-done .row > div:nth-of-type(9) .parallax--element:nth-child(7) {
  transition: all 0.6s cubic-bezier(1, 0.52, 0.29, 1.32) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(9) .parallax--element:nth-child(8), .fade-enter-done .row > div:nth-of-type(9) .parallax--element:nth-child(8) {
  transition: all 0.6s cubic-bezier(1, 0.82, 0.45, 1.33) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(9) .parallax--element:nth-child(9), .fade-enter-done .row > div:nth-of-type(9) .parallax--element:nth-child(9) {
  transition: all 0.6s cubic-bezier(1, 0.97, 0.45, 1.32) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(9) .parallax--element:nth-child(10), .fade-enter-done .row > div:nth-of-type(9) .parallax--element:nth-child(10) {
  transition: all 0.6s cubic-bezier(1, 0.79, 0.27, 1.32) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .skill-name:nth-of-type(1), .fade-enter-done .skill-name:nth-of-type(1) {
  transition: all 0.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(2), .fade-enter-done .skill-name:nth-of-type(2) {
  transition: all 0.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(3), .fade-enter-done .skill-name:nth-of-type(3) {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(4), .fade-enter-done .skill-name:nth-of-type(4) {
  transition: all 0.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(5), .fade-enter-done .skill-name:nth-of-type(5) {
  transition: all 0.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(6), .fade-enter-done .skill-name:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(7), .fade-enter-done .skill-name:nth-of-type(7) {
  transition: all 0.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(8), .fade-enter-done .skill-name:nth-of-type(8) {
  transition: all 0.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(9), .fade-enter-done .skill-name:nth-of-type(9) {
  transition: all 0.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(10), .fade-enter-done .skill-name:nth-of-type(10) {
  transition: all 1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(11), .fade-enter-done .skill-name:nth-of-type(11) {
  transition: all 1.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(12), .fade-enter-done .skill-name:nth-of-type(12) {
  transition: all 1.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(13), .fade-enter-done .skill-name:nth-of-type(13) {
  transition: all 1.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(14), .fade-enter-done .skill-name:nth-of-type(14) {
  transition: all 1.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(15), .fade-enter-done .skill-name:nth-of-type(15) {
  transition: all 1.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(16), .fade-enter-done .skill-name:nth-of-type(16) {
  transition: all 1.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(17), .fade-enter-done .skill-name:nth-of-type(17) {
  transition: all 1.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(18), .fade-enter-done .skill-name:nth-of-type(18) {
  transition: all 1.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(19), .fade-enter-done .skill-name:nth-of-type(19) {
  transition: all 1.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(20), .fade-enter-done .skill-name:nth-of-type(20) {
  transition: all 2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .form-group:nth-of-type(1), .fade-enter-done .form-group:nth-of-type(1) {
  transition: all 0.6s cubic-bezier(1, 0.72, 0.39, 1.33) 0.65s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(2), .fade-enter-done .form-group:nth-of-type(2) {
  transition: all 0.6s cubic-bezier(1, 0.64, 0.46, 1.32) 0.75s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(3), .fade-enter-done .form-group:nth-of-type(3) {
  transition: all 0.6s cubic-bezier(1, 0.55, 0.3, 1.33) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(4), .fade-enter-done .form-group:nth-of-type(4) {
  transition: all 0.6s cubic-bezier(1, 0.59, 0.43, 1.43) 0.65s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(5), .fade-enter-done .form-group:nth-of-type(5) {
  transition: all 0.6s cubic-bezier(1, 0.67, 0.33, 1.4) 0.85s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(6), .fade-enter-done .form-group:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(1, 0.61, 0.46, 1.32) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(7), .fade-enter-done .form-group:nth-of-type(7) {
  transition: all 0.6s cubic-bezier(1, 0.72, 0.32, 1.34) 0.85s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(8), .fade-enter-done .form-group:nth-of-type(8) {
  transition: all 0.6s cubic-bezier(1, 0.83, 0.29, 1.35) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(9), .fade-enter-done .form-group:nth-of-type(9) {
  transition: all 0.6s cubic-bezier(1, 0.56, 0.29, 1.45) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(10), .fade-enter-done .form-group:nth-of-type(10) {
  transition: all 0.6s cubic-bezier(1, 0.9, 0.38, 1.4) 0.55s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar, .fade-enter-done .progress:nth-of-type(1) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar:after, .fade-enter-done .progress:nth-of-type(1) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar, .fade-enter-done .progress:nth-of-type(2) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar:after, .fade-enter-done .progress:nth-of-type(2) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar, .fade-enter-done .progress:nth-of-type(3) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar:after, .fade-enter-done .progress:nth-of-type(3) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar, .fade-enter-done .progress:nth-of-type(4) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar:after, .fade-enter-done .progress:nth-of-type(4) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar, .fade-enter-done .progress:nth-of-type(5) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar:after, .fade-enter-done .progress:nth-of-type(5) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar, .fade-enter-done .progress:nth-of-type(6) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar:after, .fade-enter-done .progress:nth-of-type(6) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar, .fade-enter-done .progress:nth-of-type(7) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar:after, .fade-enter-done .progress:nth-of-type(7) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar, .fade-enter-done .progress:nth-of-type(8) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar:after, .fade-enter-done .progress:nth-of-type(8) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar, .fade-enter-done .progress:nth-of-type(9) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar:after, .fade-enter-done .progress:nth-of-type(9) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar, .fade-enter-done .progress:nth-of-type(10) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar:after, .fade-enter-done .progress:nth-of-type(10) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar, .fade-enter-done .progress:nth-of-type(11) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar:after, .fade-enter-done .progress:nth-of-type(11) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar, .fade-enter-done .progress:nth-of-type(12) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar:after, .fade-enter-done .progress:nth-of-type(12) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar, .fade-enter-done .progress:nth-of-type(13) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar:after, .fade-enter-done .progress:nth-of-type(13) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar, .fade-enter-done .progress:nth-of-type(14) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar:after, .fade-enter-done .progress:nth-of-type(14) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar, .fade-enter-done .progress:nth-of-type(15) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar:after, .fade-enter-done .progress:nth-of-type(15) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar, .fade-enter-done .progress:nth-of-type(16) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar:after, .fade-enter-done .progress:nth-of-type(16) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar, .fade-enter-done .progress:nth-of-type(17) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar:after, .fade-enter-done .progress:nth-of-type(17) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar, .fade-enter-done .progress:nth-of-type(18) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar:after, .fade-enter-done .progress:nth-of-type(18) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar, .fade-enter-done .progress:nth-of-type(19) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar:after, .fade-enter-done .progress:nth-of-type(19) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar, .fade-enter-done .progress:nth-of-type(20) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar:after, .fade-enter-done .progress:nth-of-type(20) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}

.fade-enter-active .row > div:nth-of-type(10) .parallax--element:nth-child(1), .fade-enter-done .row > div:nth-of-type(10) .parallax--element:nth-child(1) {
  transition: all 0.6s cubic-bezier(1, 0.92, 0.44, 1.34) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(10) .parallax--element:nth-child(2), .fade-enter-done .row > div:nth-of-type(10) .parallax--element:nth-child(2) {
  transition: all 0.6s cubic-bezier(1, 0.67, 0.39, 1.34) 0.4s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(10) .parallax--element:nth-child(3), .fade-enter-done .row > div:nth-of-type(10) .parallax--element:nth-child(3) {
  transition: all 0.6s cubic-bezier(1, 0.8, 0.3, 1.4) 0.3s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(10) .parallax--element:nth-child(4), .fade-enter-done .row > div:nth-of-type(10) .parallax--element:nth-child(4) {
  transition: all 0.6s cubic-bezier(1, 0.9, 0.36, 1.34) 0.2s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(10) .parallax--element:nth-child(5), .fade-enter-done .row > div:nth-of-type(10) .parallax--element:nth-child(5) {
  transition: all 0.6s cubic-bezier(1, 0.79, 0.38, 1.39) 0.2s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(10) .parallax--element:nth-child(6), .fade-enter-done .row > div:nth-of-type(10) .parallax--element:nth-child(6) {
  transition: all 0.6s cubic-bezier(1, 0.99, 0.37, 1.34) 0.3s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(10) .parallax--element:nth-child(7), .fade-enter-done .row > div:nth-of-type(10) .parallax--element:nth-child(7) {
  transition: all 0.6s cubic-bezier(1, 0.97, 0.38, 1.39) 0.5s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(10) .parallax--element:nth-child(8), .fade-enter-done .row > div:nth-of-type(10) .parallax--element:nth-child(8) {
  transition: all 0.6s cubic-bezier(1, 0.85, 0.46, 1.27) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(10) .parallax--element:nth-child(9), .fade-enter-done .row > div:nth-of-type(10) .parallax--element:nth-child(9) {
  transition: all 0.6s cubic-bezier(1, 0.74, 0.38, 1.35) 0.1s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .row > div:nth-of-type(10) .parallax--element:nth-child(10), .fade-enter-done .row > div:nth-of-type(10) .parallax--element:nth-child(10) {
  transition: all 0.6s cubic-bezier(1, 0.8, 0.4, 1.45) 0.2s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .skill-name:nth-of-type(1), .fade-enter-done .skill-name:nth-of-type(1) {
  transition: all 0.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(2), .fade-enter-done .skill-name:nth-of-type(2) {
  transition: all 0.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(3), .fade-enter-done .skill-name:nth-of-type(3) {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(4), .fade-enter-done .skill-name:nth-of-type(4) {
  transition: all 0.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(5), .fade-enter-done .skill-name:nth-of-type(5) {
  transition: all 0.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(6), .fade-enter-done .skill-name:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(7), .fade-enter-done .skill-name:nth-of-type(7) {
  transition: all 0.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(8), .fade-enter-done .skill-name:nth-of-type(8) {
  transition: all 0.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(9), .fade-enter-done .skill-name:nth-of-type(9) {
  transition: all 0.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(10), .fade-enter-done .skill-name:nth-of-type(10) {
  transition: all 1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(11), .fade-enter-done .skill-name:nth-of-type(11) {
  transition: all 1.1s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(12), .fade-enter-done .skill-name:nth-of-type(12) {
  transition: all 1.2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(13), .fade-enter-done .skill-name:nth-of-type(13) {
  transition: all 1.3s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(14), .fade-enter-done .skill-name:nth-of-type(14) {
  transition: all 1.4s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(15), .fade-enter-done .skill-name:nth-of-type(15) {
  transition: all 1.5s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(16), .fade-enter-done .skill-name:nth-of-type(16) {
  transition: all 1.6s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(17), .fade-enter-done .skill-name:nth-of-type(17) {
  transition: all 1.7s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(18), .fade-enter-done .skill-name:nth-of-type(18) {
  transition: all 1.8s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(19), .fade-enter-done .skill-name:nth-of-type(19) {
  transition: all 1.9s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .skill-name:nth-of-type(20), .fade-enter-done .skill-name:nth-of-type(20) {
  transition: all 2s cubic-bezier(0.02, 0.01, 0.47, 1) !important;
}
.fade-enter-active .form-group:nth-of-type(1), .fade-enter-done .form-group:nth-of-type(1) {
  transition: all 0.6s cubic-bezier(1, 0.64, 0.48, 1.37) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(2), .fade-enter-done .form-group:nth-of-type(2) {
  transition: all 0.6s cubic-bezier(1, 0.52, 0.29, 1.28) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(3), .fade-enter-done .form-group:nth-of-type(3) {
  transition: all 0.6s cubic-bezier(1, 0.64, 0.34, 1.34) 0.85s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(4), .fade-enter-done .form-group:nth-of-type(4) {
  transition: all 0.6s cubic-bezier(1, 0.61, 0.5, 1.26) 0.65s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(5), .fade-enter-done .form-group:nth-of-type(5) {
  transition: all 0.6s cubic-bezier(1, 0.82, 0.43, 1.31) 0.75s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(6), .fade-enter-done .form-group:nth-of-type(6) {
  transition: all 0.6s cubic-bezier(1, 0.6, 0.5, 1.31) 0.75s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(7), .fade-enter-done .form-group:nth-of-type(7) {
  transition: all 0.6s cubic-bezier(1, 0.84, 0.41, 1.31) 0.85s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(8), .fade-enter-done .form-group:nth-of-type(8) {
  transition: all 0.6s cubic-bezier(1, 0.69, 0.45, 1.25) 0.65s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(9), .fade-enter-done .form-group:nth-of-type(9) {
  transition: all 0.6s cubic-bezier(1, 0.69, 0.31, 1.27) 0.85s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .form-group:nth-of-type(10), .fade-enter-done .form-group:nth-of-type(10) {
  transition: all 0.6s cubic-bezier(1, 0.92, 0.43, 1.27) 0.95s, top 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s, bottom 0s cubic-bezier(0.02, 0.01, 0.47, 1) 0s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar, .fade-enter-done .progress:nth-of-type(1) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(1) .progress-bar:after, .fade-enter-done .progress:nth-of-type(1) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar, .fade-enter-done .progress:nth-of-type(2) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(2) .progress-bar:after, .fade-enter-done .progress:nth-of-type(2) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.1s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar, .fade-enter-done .progress:nth-of-type(3) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(3) .progress-bar:after, .fade-enter-done .progress:nth-of-type(3) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.2s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar, .fade-enter-done .progress:nth-of-type(4) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(4) .progress-bar:after, .fade-enter-done .progress:nth-of-type(4) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.3s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar, .fade-enter-done .progress:nth-of-type(5) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(5) .progress-bar:after, .fade-enter-done .progress:nth-of-type(5) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.4s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar, .fade-enter-done .progress:nth-of-type(6) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(6) .progress-bar:after, .fade-enter-done .progress:nth-of-type(6) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.5s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar, .fade-enter-done .progress:nth-of-type(7) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(7) .progress-bar:after, .fade-enter-done .progress:nth-of-type(7) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.6s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar, .fade-enter-done .progress:nth-of-type(8) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(8) .progress-bar:after, .fade-enter-done .progress:nth-of-type(8) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.7s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar, .fade-enter-done .progress:nth-of-type(9) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(9) .progress-bar:after, .fade-enter-done .progress:nth-of-type(9) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.8s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar, .fade-enter-done .progress:nth-of-type(10) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(10) .progress-bar:after, .fade-enter-done .progress:nth-of-type(10) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 1.9s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar, .fade-enter-done .progress:nth-of-type(11) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(11) .progress-bar:after, .fade-enter-done .progress:nth-of-type(11) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar, .fade-enter-done .progress:nth-of-type(12) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(12) .progress-bar:after, .fade-enter-done .progress:nth-of-type(12) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.1s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar, .fade-enter-done .progress:nth-of-type(13) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(13) .progress-bar:after, .fade-enter-done .progress:nth-of-type(13) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.2s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar, .fade-enter-done .progress:nth-of-type(14) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(14) .progress-bar:after, .fade-enter-done .progress:nth-of-type(14) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.3s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar, .fade-enter-done .progress:nth-of-type(15) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(15) .progress-bar:after, .fade-enter-done .progress:nth-of-type(15) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.4s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar, .fade-enter-done .progress:nth-of-type(16) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(16) .progress-bar:after, .fade-enter-done .progress:nth-of-type(16) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.5s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar, .fade-enter-done .progress:nth-of-type(17) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(17) .progress-bar:after, .fade-enter-done .progress:nth-of-type(17) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.6s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar, .fade-enter-done .progress:nth-of-type(18) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(18) .progress-bar:after, .fade-enter-done .progress:nth-of-type(18) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.7s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar, .fade-enter-done .progress:nth-of-type(19) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(19) .progress-bar:after, .fade-enter-done .progress:nth-of-type(19) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.8s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar, .fade-enter-done .progress:nth-of-type(20) .progress-bar {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}
.fade-enter-active .progress:nth-of-type(20) .progress-bar:after, .fade-enter-done .progress:nth-of-type(20) .progress-bar:after {
  transition: all var(--faster-transition-speed) cubic-bezier(0.02, 0.01, 0.47, 1) 2.9s;
}

.fade-exit .row:nth-of-type(1) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.fade-exit .row:nth-of-type(2) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.fade-exit .row:nth-of-type(3) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.fade-exit .row:nth-of-type(4) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.fade-exit .row:nth-of-type(5) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.fade-exit .row:nth-of-type(6) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.fade-exit .row:nth-of-type(7) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.fade-exit .row:nth-of-type(8) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.fade-exit .row:nth-of-type(9) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.fade-exit .row:nth-of-type(10) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.fade-exit .row:nth-of-type(11) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.fade-exit .row:nth-of-type(12) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.fade-exit .row:nth-of-type(13) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.fade-exit .row:nth-of-type(14) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.fade-exit .row:nth-of-type(15) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.fade-exit .row:nth-of-type(16) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.fade-exit .row:nth-of-type(17) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.fade-exit .row:nth-of-type(18) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.fade-exit .row:nth-of-type(19) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.fade-exit .row:nth-of-type(20) .parallax--element {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .skill-name:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .skill-name:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .skill-name:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .skill-name:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .skill-name:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .skill-name:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .skill-name:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .skill-name:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .skill-name:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .skill-name:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .skill-name:nth-of-type(11) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .skill-name:nth-of-type(12) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .skill-name:nth-of-type(13) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .skill-name:nth-of-type(14) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .skill-name:nth-of-type(15) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.525s !important;
}
.fade-exit .skill-name:nth-of-type(16) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.55s !important;
}
.fade-exit .skill-name:nth-of-type(17) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.575s !important;
}
.fade-exit .skill-name:nth-of-type(18) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.6s !important;
}
.fade-exit .skill-name:nth-of-type(19) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.625s !important;
}
.fade-exit .skill-name:nth-of-type(20) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.65s !important;
}
.fade-exit .form-group:nth-of-type(1) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(2) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .form-group:nth-of-type(3) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(4) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .form-group:nth-of-type(5) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(6) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .form-group:nth-of-type(7) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(8) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .form-group:nth-of-type(9) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .form-group:nth-of-type(10) {
  transition: all 0.45s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(1) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.025s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(2) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.05s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(3) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.075s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(4) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.1s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(5) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.125s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(6) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.15s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(7) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.175s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(8) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.2s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(9) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.225s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(10) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.25s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(11) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.275s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(12) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.3s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(13) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.325s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(14) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.35s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(15) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.375s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(16) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.4s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(17) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.425s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(18) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.45s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(19) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.475s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}
.fade-exit .progress:nth-of-type(20) .progress-bar:after {
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1) 0.5s !important;
}

.error--container {
  width: 100%;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation-delay: 0.5s;
}

header {
  min-height: var(--header-height);
  z-index: 999;
}

.logo {
  transform: scale(0);
  opacity: 0;
  padding: 0;
  animation-name: appearance;
  animation-duration: 0.6s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.75, -0.45, 0.3, 1.4);
  transform-origin: center;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
}

.navbar {
  position: relative;
  padding: 2rem 0;
  width: 100%;
  justify-content: flex-end;
}
.navbar .navbar-collapse {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  opacity: 0;
  transform: translateY(-200%);
  transition: all 0.6s ease-in-out 0.9s;
  visibility: hidden;
}
.navbar .navbar-collapse .navbar-nav {
  align-items: center;
}
.navbar .navbar-collapse .navbar-nav:before, .navbar .navbar-collapse .navbar-nav:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--tertiary-color);
}
.navbar .navbar-collapse .navbar-nav a {
  font-size: var(--menu-size);
  text-transform: lowercase;
  color: var(--secondary-color);
  transform: scale(0);
  transition: all 0.6s ease-in-out;
  z-index: 1;
}
.navbar .navbar-collapse .navbar-nav a:nth-child(1) {
  transition-delay: 0.2s;
}
.navbar .navbar-collapse .navbar-nav a:nth-child(2) {
  transition-delay: 0.4s;
}
.navbar .navbar-collapse .navbar-nav a:nth-child(3) {
  transition-delay: 0.6s;
}
.navbar .navbar-collapse .navbar-nav a:nth-child(4) {
  transition-delay: 0.8s;
}
.navbar .navbar-collapse .navbar-nav a:nth-child(5) {
  transition-delay: 1s;
}
.navbar .navbar-collapse .navbar-nav a:nth-child(6) {
  transition-delay: 1.2s;
}
.navbar .navbar-collapse .navbar-nav a:nth-child(7) {
  transition-delay: 1.4s;
}
.navbar .navbar-collapse .navbar-nav a:before, .navbar .navbar-collapse .navbar-nav a:after {
  width: 0;
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
  height: 2.5px;
  background-color: #fff;
  transition: all 0.6s ease-in-out, opacity var(--faster-transition-speed) ease-in-out var(--faster-transition-speed);
  opacity: 0;
}
.navbar .navbar-collapse .navbar-nav a:hover {
  text-decoration: none;
}
.navbar .navbar-collapse .navbar-nav a:hover:before, .navbar .navbar-collapse .navbar-nav a:hover:after {
  opacity: 1;
}
.navbar .navbar-collapse .navbar-nav a:hover:before {
  width: 50%;
  left: 0;
  transition: all 0.6s ease-in-out, opacity var(--faster-transition-speed) ease-in-out 0.1s;
}
.navbar .navbar-collapse .navbar-nav a:hover:after {
  width: 50%;
  right: 0;
  transition: all 0.6s ease-in-out, opacity var(--faster-transition-speed) ease-in-out 0.1s;
}
.navbar .navbar-collapse .navbar-nav a.active-link {
  pointer-events: none;
}
.navbar .navbar-collapse .navbar-nav a.active-link:before, .navbar .navbar-collapse .navbar-nav a.active-link:after {
  opacity: 1;
}
.navbar .navbar-collapse .navbar-nav a.active-link:before {
  width: 50%;
  left: 0;
  transition: all 0.6s ease-in-out;
}
.navbar .navbar-collapse .navbar-nav a.active-link:after {
  width: 50%;
  right: 0;
  transition: all 0.6s ease-in-out;
}
.navbar .navbar-collapse.show {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.6s ease-in-out;
  visibility: visible;
}
.navbar .navbar-collapse.show .navbar-nav:before {
  opacity: 1;
  z-index: -1;
}
.navbar .navbar-collapse.show .navbar-nav:after {
  opacity: 1;
  z-index: -1;
}
.navbar .navbar-collapse.show .navbar-nav a {
  transform: scale(1);
  transition: all var(--faster-transition-speed) ease-in-out;
}
.navbar .navbar-collapse.show .navbar-nav a:nth-child(1) {
  transition-delay: 0.3333333333s;
}
.navbar .navbar-collapse.show .navbar-nav a:nth-child(2) {
  transition-delay: 0.6666666667s;
}
.navbar .navbar-collapse.show .navbar-nav a:nth-child(3) {
  transition-delay: 1s;
}
.navbar .navbar-collapse.show .navbar-nav a:nth-child(4) {
  transition-delay: 1.3333333333s;
}
.navbar .navbar-collapse.show .navbar-nav a:nth-child(5) {
  transition-delay: 1.6666666667s;
}
.navbar .navbar-collapse.show .navbar-nav a:nth-child(6) {
  transition-delay: 2s;
}
.navbar .navbar-collapse.show .navbar-nav a:nth-child(7) {
  transition-delay: 2.3333333333s;
}
.navbar .navbar-toggler {
  z-index: 2;
  border: none;
  padding: 0;
  order: 2;
}
.navbar .navbar-toggler:focus {
  outline: none;
}

.navbar-toggler {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40px;
  height: 50px;
  border: none !important;
  padding: 0 !important;
  transform: scale(0);
  opacity: 0;
  animation-name: appearance;
  animation-duration: 0.6s;
  animation-delay: 0.9s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.75, -0.45, 0.36, 1.77);
  transform-origin: center;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
}
.navbar-toggler .line {
  width: 80%;
  float: left;
  height: 2px;
  background-color: var(--secondary-color);
  margin: 0 auto 4px;
  transition: 0.6s;
}
.navbar-toggler .line:nth-child(2) {
  background: transparent;
  transform: translate(-200%);
  transition: 0.6s;
  margin: 0 0 4px;
  z-index: -1n;
  opacity: 0;
}
.navbar-toggler .line:nth-child(1) {
  transform: rotate(45deg) translate(0.5px, 3px);
  transition: 0.6s;
  width: 80%;
}
.navbar-toggler .line:nth-child(3) {
  transform: rotate(-45deg) translate(5.5px, -8px);
  transition: 0.6s;
}
.navbar-toggler.collapsed .line {
  background-color: var(--secondary-color);
  transition: 0.6s;
}
.navbar-toggler.collapsed .line:nth-child(2) {
  transform: translateX(0);
  background-color: var(--tertiary-color);
  transition: 0.6s;
  opacity: 1;
  width: 100%;
}
.navbar-toggler.collapsed .line:nth-child(1) {
  transform: rotate(0);
  transition: 0.6s;
  width: 60%;
}
.navbar-toggler.collapsed .line:nth-child(3) {
  transform: rotate(0);
  transition: 0.6s;
}

.form-control {
  background: var(--primary-color);
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  border-radius: 0;
  padding: 0.5rem;
}
.form-control:focus {
  background: var(--primary-color);
  border-color: var(--tertiary-color);
  color: var(--tertiary-color);
  outline: none;
  box-shadow: none;
}

.contact--message {
  display: inline-block;
  visibility: hidden;
  opacity: 0;
  transition: var(--transition-speed) ease-in-out;
  max-height: 0;
  width: auto;
}
.contact--message h1 {
  display: inline-block;
}
.contact--message span {
  padding-right: 0.25rem;
}
.contact--message.contact--message--show {
  visibility: visible;
  max-height: 300px;
  opacity: 1;
  transition: var(--transition-speed) ease-in-out 0.15s, opacity var(--transition-speed) ease-in-out 0.3s;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary-color) var(--primary-color);
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: var(--primary-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 0;
}

.btn {
  font-size: 1rem !important;
  background: var(--primary-color) !important;
  color: var(--text-color) !important;
  border: 2px solid var(--secondary-color) !important;
  border-radius: 0 !important;
  padding: 0.5rem !important;
}
.btn:focus {
  background: var(--primary-color);
  border-color: var(--tertiary-color);
  color: var(--tertiary-color);
  outline: none;
  box-shadow: none;
}

a {
  font-family: "Montserrat", sans-serif;
  color: var(--secondary-color);
  font-weight: 300;
}
a:hover {
  text-decoration: none;
  color: var(--text-color);
}

.big {
  height: 400px;
}

.alignright {
  text-align: right;
}

.alignleft {
  text-align: left;
}

.aligncenter {
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .btn {
    font-size: 12px !important;
    padding: 0.25rem !important;
  }
}
.progress {
  height: 5px;
  font-size: 0.75rem;
  background-color: transparent;
  border-radius: 0;
  overflow: visible;
}
.progress .progress-bar {
  position: relative;
  background-color: var(--secondary-color) !important;
  overflow: visible;
}
.progress .progress-bar:after {
  content: attr(aria-valuenow) "%";
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
  position: absolute;
  left: 102.5%;
  color: var(--tertiary-color);
}

h1, h2, h3, h4 {
  color: var(--tertiary-color);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

h1 {
  font-size: 3rem;
  color: var(--secondary-color);
  padding: 0;
  position: relative;
  display: inline-block;
  margin: 0;
}

p {
  font-size: 1rem;
  color: var(--text-color);
  word-break: break-word;
}

@media only screen and (max-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 2.25rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.25rem;
  }
  h3 {
    font-size: 1rem;
  }
  h4 {
    font-size: 1rem;
  }
  p {
    font-size: 14px;
  }
}
.footer {
  background: var(--tertiary-color);
  color: var(--primary-color);
  padding: var(--medium-padding);
  margin-top: var(--big-padding);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.animation {
  opacity: 0;
  animation-duration: var(--transition-speed);
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.animation--zoom--out {
  animation-name: zoom--out;
}
.animation--top {
  animation-name: appearance--top;
}
.animation--bottom {
  animation-name: appearance--bottom;
}
.animation--left {
  animation-name: appearance--left;
}
.animation.el_in_viewport {
  animation-play-state: running;
}

@keyframes appearance {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes wordAppearance {
  from {
    transform: translateY(200%) translate3d(1px, 1px, 1px);
    opacity: 0;
  }
  to {
    transform: translateY(0) translate3d(1px, 1px, 1px);
    opacity: 1;
  }
}
@keyframes appearance--top {
  0% {
    transform: translateZ(0) translateY(-25px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes appearance--top--out {
  0% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateZ(0) translateY(-25px);
    opacity: 0;
  }
}
@keyframes appearance--bottom {
  0% {
    transform: translateZ(0) translateY(25px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes appearance--bottom--out {
  0% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateZ(0) translateY(25px);
    opacity: 0;
  }
}
@keyframes appearance--left {
  from {
    transform: translateX(-75px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes zoom--out {
  0% {
    transform: translateZ(0) scale(1.1);
    opacity: 0;
  }
  100% {
    transform: translateZ(0) scale(1);
    opacity: 1;
  }
}
@keyframes zoom--out--out {
  0% {
    transform: translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateZ(0) scale(1.1);
    opacity: 0;
  }
}
.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50% -50%);
  display: inline-block;
  width: 80px;
  height: 80px;
}

.loading:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid var(--secondary-color);
  border-color: #fff transparent var(--secondary-color) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
body {
  background: var(--primary-color);
  color: var(--secondary-color);
  overflow-x: hidden;
  overflow-y: scroll;
}

.lazy {
  background-image: none;
}

.background .parallax--element {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  box-sizing: content-box;
  border: 1.5rem var(--secondary-color) solid;
}

.transition-group {
  position: relative;
}

.test {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.test > .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--big-padding);
  min-height: calc(100vh - 144px);
}

.blog {
  padding: 2rem 0;
}
.blog p {
  padding: 0;
}
.blog:first-of-type {
  min-height: calc(100vh - 150px);
}
.blog:first-of-type h1 {
  font-size: clamp(3.44rem, 6.34vw + 0.75rem, 6.81rem);
  word-break: keep-all;
  position: relative;
  color: var(--tertiary-color);
}
.blog:first-of-type h1::before {
  content: "<";
  color: var(--secondary-color);
}
.blog:first-of-type h1::after {
  content: " />";
  position: absolute;
  right: -35%;
  color: var(--secondary-color);
}

.modal-backdrop.show {
  opacity: 0.975;
}

.modal {
  padding: 0 !important;
  overflow: hidden !important;
}
.modal .modal-dialog .modal-content {
  background-color: transparent;
  border-radius: 0;
  border: none;
}
.modal .modal-dialog .modal-content .modal-header {
  position: absolute;
  top: 0;
  right: 0.5rem;
  z-index: 999;
  border: none;
}
.modal .modal-dialog .modal-content .modal-header .line:first-of-type {
  transform: rotate(-45deg);
}
.modal .modal-dialog .modal-content .modal-header .line:last-of-type {
  transform: rotate(45deg);
}
.modal .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  color: var(--secondary-color);
  text-shadow: none;
  font-size: 3rem;
}
.modal .modal-dialog .modal-content .modal-header .close:focus {
  outline: 0;
}
.modal .modal-dialog .modal-content .modal-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.word {
  font-family: "Roboto", sans-serif;
  font-family: "Patua One", cursive;
  font-size: 1.25rem;
  display: inline-block;
}

.footerBottom {
  min-height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.link {
  color: var(--secondary-color);
  text-decoration: underline;
}/*# sourceMappingURL=styles.css.map */