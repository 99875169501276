.progress {
  height: 5px;
  font-size: .75rem;
  background-color: transparent;
  border-radius: 0;
  overflow: visible;
  .progress-bar {
    position: relative;
    background-color: var(--secondary-color) !important;
    overflow: visible;
    &:after {
      content: attr(aria-valuenow) '%';
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 16px;
      position: absolute;
      left: 102.5%;
      color: var(--tertiary-color);
    }
  }
}
