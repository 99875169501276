.navbar {
  position: relative;
  padding: 2rem 0;
  width: 100%;
  justify-content: flex-end;
  .navbar-collapse {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    opacity: 0;
    transform: translateY(-200%);
    transition: all .6s ease-in-out .9s;
    visibility: hidden;
    .navbar-nav {
      align-items: center;
      &:before, &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: var(--tertiary-color);

      }

      a {
        font-size: var(--menu-size);
        text-transform: lowercase;
        color: var(--secondary-color);
        transform: scale(0);
        transition: all .6s ease-in-out;
        z-index: 1;
        @for $i from 1 through 7 {
          &:nth-child(#{$i}) {
            transition-delay: ($i / 5) + s;
          }
        }
        &:before, &:after {
          width: 0;
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%; right: 50%;
          height: 2.5px;
          background-color: #fff;
          transition: all .6s ease-in-out, opacity var(--faster-transition-speed) ease-in-out var(--faster-transition-speed);
          opacity: 0;
        }
        &:hover {
          text-decoration: none;
          &:before, &:after {
            opacity: 1;
          }
          &:before {
            width: 50%;
            left: 0;
            transition: all .6s ease-in-out, opacity var(--faster-transition-speed) ease-in-out .1s;
          }
          &:after {
            width: 50%;
            right: 0;
            transition: all .6s ease-in-out, opacity var(--faster-transition-speed) ease-in-out .1s;
          }
        }
        &.active-link {
          pointer-events: none;
          &:before, &:after {
            opacity: 1;
          }
          &:before {
            width: 50%;
            left: 0;
            transition: all .6s ease-in-out;
          }
          &:after {
            width: 50%;
            right: 0;
            transition: all .6s ease-in-out;
          }
        }
      }
    }
    &.show {
      opacity: 1;
      transform: translateY(0);
      transition: all .6s ease-in-out;
      visibility: visible;
      .navbar-nav {
        &:before {
          opacity: 1;
          z-index: -1;

        }
        &:after {
          opacity: 1;
          z-index: -1;

        }
        a{
          transform: scale(1);
          transition: all var(--faster-transition-speed) ease-in-out;
          @for $i from 1 through 7 {
            &:nth-child(#{$i}) {
              transition-delay: ($i / 3) + s;
            }
          }
        }
      }
    }

  }
  .navbar-toggler {
    z-index: 2;
    border: none;
    padding: 0;
    order: 2;
    &:focus {
      outline: none;
    }
  }
}

.navbar-toggler {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40px;
  height: 50px;
  border: none !important;
  padding: 0 !important;
  transform: scale(0);
  opacity: 0;
  animation-name: appearance;
  animation-duration: .6s;
  animation-delay: .9s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.75,-0.45,.36,1.77);
  transform-origin: center;
    -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .line{
    width: 80%;
    float: left;
    height: 2px;
    background-color: var(--secondary-color);
    margin: 0 auto 4px;
    transition: .6s;

    &:nth-child(2) {
      background: transparent;
      transform: translate(-200%);
      transition: .6s;
      margin: 0 0 4px;
      z-index: -1n;
      opacity: 0;
    }
    &:nth-child(1) {
      transform: rotate(45deg) translate(.5px, 3px);
      transition: .6s;
      width: 80%;
    }
    &:nth-child(3) {
      transform: rotate(-45deg) translate(5.5px,-8px);
      transition: .6s;
    }
  }
  &.collapsed {
    .line{
      background-color: var(--secondary-color);
      transition: .6s;

      &:nth-child(2) {
        transform: translateX(0);
        background-color: var(--tertiary-color);
        transition: .6s;
        opacity: 1;
        width: 100%;

      }
      &:nth-child(1) {
        transform: rotate(0);
        transition: .6s;
        width: 60%;
      }
      &:nth-child(3) {
        transform: rotate(0);
        transition: .6s;
      }
    }
  }
}
