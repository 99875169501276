.form-control {
  background: var(--primary-color);
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  border-radius: 0;
  padding: .5rem;
  &:focus {
    background: var(--primary-color);
    border-color: var(--tertiary-color);
    color: var(--tertiary-color);
    outline: none;
    box-shadow: none;
  }
}

.contact--message {
  display: inline-block;
  visibility: hidden;
  opacity: 0;
  transition: var(--transition-speed) ease-in-out;
  max-height: 0;
  width: auto;
  h1 {
    display: inline-block;
  }
  span {
    padding-right: .25rem;
  }
  &.contact--message--show {
    visibility: visible;
    max-height: 300px;
    opacity: 1;
    transition: var(--transition-speed) ease-in-out .15s, opacity var(--transition-speed) ease-in-out .30s;
  }
}
