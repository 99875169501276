.footer {

  background: var(--tertiary-color);
  color: var(--primary-color);
  padding: var(--medium-padding);
  margin-top: var(--big-padding);
  display: flex;
  flex-direction: row;
  justify-content: center;
}
