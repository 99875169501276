@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min + floor($rand * (($max - $min) + 1));

  @return $randomNum;
}

.parallax--element {
  position: relative;
}

.fade-enter{
  opacity: 0;
  transform-origin: bottom;

  .form-group {
    transform: scale(0);
  }
  .text-container, .skill-name {
    transform: translateX(-2000%);

    opacity: 0;
    transition: all .45s cubic-bezier(.02, .01, .47, 1);
  }

  .parallax--element{
    opacity: 0;
    transform: scale(0);
  }

  .progress-bar{
    max-width: 0;
    &:after {
      opacity: 0;
    }
  }

}
.fade-appear {
  @extend .fade-enter;
}

.fade-appear-done {
  opacity: 1;
  transform: translateX(0);
  transform-origin: center;
  transition: all .45s cubic-bezier(.02, .01, .47, 1) .45s;
  .progress-bar{
    max-width: 100%;
    &:after {
      opacity: 1;
    }
  }
  .parallax--element {
    opacity: 0;
    transform: scale(0);
    &.el_in_viewport{
      opacity: 1;
      transform: scale(1) translate3d(1px, 1px, 1px);
      &.project--images {
        transition: all .6s cubic-bezier(1,.93,.5,1.33) .5s,top 0s cubic-bezier(.02,.01,.47,1) 0s,bottom 0s cubic-bezier(.02,.01,.47,1) 0s;
      }
      .word {
        opacity: 0;
        transform: translateY(200%) translate3d(1px, 1px, 1px);
        animation-name: wordAppearance;
        animation-duration: 0.6s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.75, -0.45, 0.3, 1.4);
        @for $l from 1 through 200 {
            &:nth-of-type(#{$l}) {
              animation-delay: 0.02s*$l + .5s !important;
            }
          }
      }
    }
  }
  .text-container, .skill-name {
    opacity: 1;
    transform: translateX(0);
    transition: all .45s cubic-bezier(.02, .01, .47, 1);
  }

  .text-container {
    transition-delay: .9s;
  }

  .form-group {
    transform: scale(1);
  }
}
.fade-enter-done {
  @extend .fade-appear-done;
}
.fade-appear-done {
  transition: all .45s cubic-bezier(.02, .01, .47, 1);
}
.fade-exit {
  opacity: 0;
  //transform: translateX(150%);
  transition: all .45s cubic-bezier(.02, .01, .47, 1) 1s;
  .progress-bar {
    transform: translateX(100vw);
    &:after {
    }
  }
  .parallax--element {
    transform: translateX(100vw);
    &.el_in_viewport{
      transform: translateX(100vw);
    }

  }

  .text-container, .skill-name {
    transform: translateX(200vw);

    transition: all .45s cubic-bezier(.02, .01, .47, 1);
  }

  .form-group {
    transform: translateX(100vw);
  }

}

@for $i from 1 through 10 {
  .fade-enter-active, .fade-enter-done {
    .row {
      &> div:nth-of-type(#{$i}) {
        .parallax--element {
          @for $k from 1 through 10{
            &:nth-child(#{$k}) {
              transition: all .6s cubic-bezier(1,randomNum(50,100)/100,randomNum(25,50)/100,randomNum(125,145)/100) random(5)*.1s,  top 0s cubic-bezier(.02, .01, .47, 1) 0s, bottom 0s cubic-bezier(.02, .01, .47, 1) 0s;
            }
          }
        }

      }

    }
    @for $l from 1 through 20 {
      .skill-name {
        &:nth-of-type(#{$l}) {
          transition: all 0.1s*$l cubic-bezier(.02, .01, .47, 1) !important;
        }
      }
    }

    @for $o from 1 through 10 {
      .form-group {
        &:nth-of-type(#{$o}) {
          transition: all .6s cubic-bezier(1,randomNum(50,100)/100,randomNum(25,50)/100,randomNum(125,145)/100) random(5)*.1s + .45s,  top 0s cubic-bezier(.02, .01, .47, 1) 0s, bottom 0s cubic-bezier(.02, .01, .47, 1) 0s;
        }
      }
    }
    @for $k from 1 through 20 {
      .progress {
        &:nth-of-type(#{$k}) {
          .progress-bar {
            transition: all var(--faster-transition-speed) cubic-bezier(.02, .01, .47, 1) 0.9s+(0.1s*$k);
            &:after {
              transition: all var(--faster-transition-speed) cubic-bezier(.02, .01, .47, 1) 0.9s+(0.1s*$k);
            }
          }
        }
      }
    }
  }

}
@for $i from 1 through 20 {
  .fade-exit {
    .row {
      &:nth-of-type(#{$i}) {
        .parallax--element {
          transition: all .45s cubic-bezier(.02, .01, .47, 1) 0.025s*random(5) !important;
        }


      }
    }
    @for $l from 1 through 20 {
      .skill-name {
        &:nth-of-type(#{$l}) {
          transition: all .45s cubic-bezier(.02, .01, .47, 1) .15s + 0.025s*$l !important;
        }
      }
    }
    @for $o from 1 through 10 {
      .form-group {
        &:nth-of-type(#{$o}) {
          transition: all .45s cubic-bezier(.02, .01, .47, 1) 0.025s*random(5) !important;
        }
      }
    }
    @for $m from 1 through 20 {
      .progress {
        &:nth-of-type(#{$m}) {
          .progress-bar {
            transition: all .3s cubic-bezier(.02, .01, .47, 1) 0.025s*$m !important;
            &:after {
              transition: all .3s cubic-bezier(.02, .01, .47, 1) 0.025s*$m !important;
            }
          }
        }
      }
    }
  }
}
